import { Component, OnInit } from '@angular/core';
import { AuthService } from '../Helpers/AuthService';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  public userAuthenticated: Observable<boolean>;

  constructor(protected auth: AuthService) {}

  ngOnInit() {
    this.userAuthenticated = this.auth.getUserAuthenticated;
  }

  SetClassicTitle(title) {
    localStorage.setItem('ClassicTitle', title);
  }

  lnkLogout_Click() {
    localStorage.removeItem('TokenInfo');
    this.auth.logout();
  }
}
