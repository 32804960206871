import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

// const providers = [
//   { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
//   { provide: 'API_URL', useValue: { url: 'http://localhost:40050/v1/' } },
//   { provide: 'CLASSIC_URL', useValue: { url: 'https://localhost:44300/' } }
// ];

let providers = [];

if (environment.production) {
  enableProdMode();
  providers = [
    { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
    {
      provide: 'API_URL',
      useValue: { url: 'http://api.usids.devcolab.ca/v1/' }
    },
    {
      provide: 'CLASSIC_URL',
      useValue: { url: 'https://mcgillclassic.devcolab.ca/classic/' }
    }
  ];
} else {
  providers = [
    { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
    { provide: 'API_URL', useValue: { url: 'http://localhost:40050/v1/' } },
    { provide: 'CLASSIC_URL', useValue: { url: 'https://localhost:44300/' } }
  ];
}

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
