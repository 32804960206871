import { User } from './User';
import { LocationHeader } from './LocationHeader';
import { RentalReturnProduct } from './RentalReturnProduct';

export class RentalReturn {
  id: number;
  createdBy: User;
  createdBy_id: number;
  dateCreated: Date;
  dateCreatedString: string;
  lastUpdatedBy: User;
  lastUpdatedBy_id: number;
  lastUpdateDate: Date;
  isActive: boolean;
  locationHeader_id: number;
  locationHeader: LocationHeader;
  locationName: string;
  rentalReturnProducts: RentalReturnProduct[];
  clientName: string;

  constructor() {
    this.id = 0;
    this.createdBy_id = 0;
    this.createdBy = new User();
    this.dateCreated = new Date();
    this.lastUpdatedBy_id = null;
    this.lastUpdateDate = null;
    this.isActive = true;
    this.locationHeader_id = 0;
    this.locationHeader = null;
    this.rentalReturnProducts = [];
  }
}
