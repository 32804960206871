<style>
  a {
    color: black;
  }
</style>

<div class="row m-0" *ngIf="userAuthenticated.source['value']">
  <div class="col-sm-12 p-0">
    <nav class="navbar navbar-expand-lg navbar-light Menu col-sm-12">
      <table class="col-sm-9" style="color:white">
        <tr>
          <td>
            <img
              alt="USIDS"
              class="img-fluid"
              src="assets/Images/TopLogo.png"
            />
          </td>
          <td>
            <span style="font-size: large;">U.S.I.D.S.</span><br />
            <span>University Services Integrated Database System</span>
          </td>
        </tr>
      </table>

      <div class="col-sm-3 text-right">
        <span style="color:white">
          Welcome {{ auth.user }}!<br />
          <span>
            <a
              id="lnkMyProfile"
              style="color:white !important"
              [routerLink]="!auth.isSupplier ? ['classic', '~/Secured/MyProfile.aspx'] : ['classic', '~/Supplier/MyProfile.aspx']"
              >My Profile</a
            >
            &nbsp;|&nbsp;
            <a
              id="lnkLogout"
              (click)="lnkLogout_Click()"
              [routerLink]=""
              style="display:contents;color:white"
              >Logout Safely</a
            >
          </span>
        </span>
      </div>
    </nav>
    <hr class="m-0" style="background-color:white" />
    <nav class="navbar navbar-expand-lg navbar-light Menu">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item p-0" *ngFor="let form of auth.forms">
            <ng-container *ngIf="form.isClassic">
              <a
                *ngIf="form.subForms.length == 1"
                [routerLink]="['classic', form.subForms[0].url]"
                (click)="SetClassicTitle(form.name)"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                class="dropdown-btn"
                >{{ form.name }}</a
              >
              <a
                *ngIf="form.subForms.length == 0"
                [routerLink]="['classic', form.url]"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                (click)="SetClassicTitle(form.name)"
                class="dropdown-btn"
                >{{ form.name }}</a
              >
              <ng-container *ngIf="form.subForms.length > 1">
                <a
                  class="dropdown-toggle dropdown-btn"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ form.name }}
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a
                    class="dropdown-item"
                    [routerLink]="['classic', subForm.url]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="SetClassicTitle(subForm.name)"
                    *ngFor="let subForm of form.subForms"
                    >{{ subForm.name }}</a
                  >
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!form.isClassic">
              <a
                *ngIf="form.subForms.length == 1"
                [routerLink]="[form.subForms[0].url]"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                class="dropdown-btn"
                >{{ form.name }}</a
              >
              <a
                *ngIf="form.subForms.length == 0"
                [routerLink]="[form.url]"
                routerLinkActive="active"
                class="dropdown-btn"
                >{{ form.name }}</a
              >
              <ng-container *ngIf="form.subForms.length > 1">
                <a
                  class="dropdown-toggle dropdown-btn"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ form.name }}
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a
                    class="dropdown-item"
                    [routerLink]="[subForm.url]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    *ngFor="let subForm of form.subForms"
                    >{{ subForm.name }}</a
                  >
                </div>
              </ng-container>
            </ng-container>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
