<h3>Sublet List</h3>
<!--
  <fieldset>

    <legend>Filters :</legend>


  </fieldset>
-->
<fieldset>
  <legend>Sublets :</legend>
  <app-grid
    [models]="model"
    [lstGridColumn]="[
      { index: 1, name: 'id', header: 'Id', readonly: true },
      { index: 2, name: 'supplierName', header: 'Supplier', readonly: true },
      { index: 3, name: 'dateFromString', header: 'Date From', readonly: true },
      { index: 4, name: 'dateToString', header: 'Date To', readonly: true }
    ]"
    entity="SubletHeader"
    editLink="true"
  ></app-grid>
</fieldset>
