export class ClientFoapal {
  id: number;
  number: string;
  // numberString: string;
  description: string;
  createdBy_id: number;
  dateCreated: Date;
  lastUpdatedBy_id: number;
  lastUpdatedDate: Date;
  client_id: number;

  constructor() {
    this.id = 0;
    this.number = '';
    this.description = '';
    this.createdBy_id = 0;
    this.dateCreated = new Date();
    this.lastUpdatedBy_id = null;
    this.client_id = 0;
    this.lastUpdatedDate = null;
  }
}
