import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { ApiService } from '../Helpers/ApiService';
import { HttpClient } from '@angular/common/http';
import {
  ApiCallMethod
} from '../Helpers/McGillConstant';

@Component({
  selector: 'app-classic',
  templateUrl: './classic.component.html'
})
export class ClassicComponent implements OnInit, OnDestroy {
  navigationSubscription;
  classicUrl: SafeResourceUrl;
  public loading = false;
  public title = '';

  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer, private api: ApiService, private router: Router,private http: HttpClient) {
    window.onresize = function(event) {
      const height = window.innerHeight;
      const obj = (<HTMLElement>(
        document.getElementsByTagName('app-nav-menu')[0].children[0]
      )).offsetHeight;
      const object = document.getElementById('objectClassic');
      object.style.height = height - obj - 77 + 'px';
    };
  }

  ngOnInit() {
    this.route.params.subscribe((e: any) => {
      this.loading = true;
      const elem = document.createElement('div');
      elem.style.cssText =
        'position:absolute;width:100%;height:100%;opacity:0.3;z-index:100;background:#000;top:0';
      elem.id = 'divLock';
      document.body.appendChild(elem);
      this.api
      .CallWebApi(ApiCallMethod.Post, 'Tokens/ValidateToken', true, '', '')
      .then(() => {
      });
      //this.title = localStorage.getItem('ClassicTitle');
      const url =
        environment.classicUrl +
        this.route.snapshot.params.classicUrl.slice(2) +
        '?TokenInfo=' +
        localStorage.getItem('TokenInfo');
      this.classicUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  initialiseInvites() {
    // Set default values and re-fetch any data you need.
    this.loading = true;
      this.api
      .CallWebApi(ApiCallMethod.Post, 'Tokens/ValidateToken', true, '', '')
      .then(() => {
      });
      const url =
        environment.classicUrl +
        this.route.snapshot.params.classicUrl.slice(2) +
        '?TokenInfo=' +
        localStorage.getItem('TokenInfo');
      this.classicUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  disableSpinner() {
    this.loading = false;
    const elem = document.getElementById('divLock');
    if (elem != null) {
      elem.parentNode.removeChild(elem);
    }
    this.resizeWindow();
  }

  resizeWindow() {
    const height = window.outerHeight;
    const obj = (<HTMLElement>(
      document.getElementsByTagName('app-nav-menu')[0].children[0]
    )).offsetHeight;
    const object = document.getElementById('objectClassic');
    object.style.height = height - obj - 70 + 'px';
  }
}
