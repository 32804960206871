import { Injectable, Inject } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { AuthService } from "./AuthService";

@Injectable()
export class CanActivateRouteGuard implements CanActivate {
  public isActive: boolean;

  constructor(private auth: AuthService, private router: Router) {}

  canActivate(): boolean {
    let result = this.auth.getUserAuthenticated.source["value"];
    if (result) {
      if (this.auth.forms == null || this.auth.forms.length == 0)
        this.auth.showData();
      return result;
    } else {
      this.router.navigate(["/"]);
      return result;
    }
  }
}
