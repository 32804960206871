<h3>Rental Return List</h3>
<!--
  <fieldset>

    <legend>Filters :</legend>


  </fieldset>
-->
<fieldset>
  <legend>Rental returns :</legend>
  <app-grid
    [models]="model"
    [lstGridColumn]="[
      { index: 1, name: 'id', header: 'Id', readonly: true },
      { index: 2, name: 'clientName', header: 'Client', readonly: true },
      {
        index: 3,
        name: 'locationHeader_id',
        header: 'Event Number',
        readonly: true
      },
      { index: 4, name: 'locationName', header: 'Event Name', readonly: true },
      {
        index: 5,
        name: 'dateCreatedString',
        header: 'Date Returned',
        readonly: true
      }
    ]"
    entity="RentalReturn"
    editLink="true"
  ></app-grid>
</fieldset>
