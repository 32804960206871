<span
  [ngClass]="{ 'text-success': success, 'text-danger': !success }"
  *ngIf="message.length > 0"
  >{{ message }}</span
>
<table class="Grid">
  <thead>
    <tr>
      <th><b>Action</b></th>
      <th
        *ngFor="let gridColumn of lstGridColumn"
        (click)="setSort(gridColumn.header)"
      >
        <b>{{ gridColumn.header }}</b>
        <i
          class="fas fa-caret-up"
          *ngIf="sort == gridColumn.header && reverse"
        ></i>
        <i
          class="fas fa-caret-down"
          *ngIf="sort == gridColumn.header && !reverse"
        ></i>
      </th>
    </tr>
    <tr *ngFor="let model of models; let i = index">
      <td style="width: 50px !important">
        <i
          *ngIf="!model.EditMode && !editLink && model.CanEdit"
          class="fas fa-pencil-alt"
          (click)="edit(i)"
        ></i>
        <i
          *ngIf="model.EditMode && !editLink"
          class="fas fa-check-circle"
          (click)="acceptEdit(i)"
        ></i>
        <a
          *ngIf="editLink"
          [routerLink]="[model.id]"
          class="fas fa-pencil-alt"
        ></a>
        &nbsp;&nbsp;&nbsp;
        <i
          *ngIf="!model.EditMode"
          class="fas fa-trash-alt"
          (click)="delete(i)"
        ></i>
        <i
          *ngIf="model.EditMode"
          class="fas fa-times-circle"
          (click)="cancelEdit(i)"
        ></i>
      </td>
      <td
        *ngFor="let column of lstColumn"
        [ngStyle]="column.width != null && { width: +column.width + 'px' }"
      >
        <span
          *ngIf="!model.EditMode || (model.EditMode && !column.canEdit)"
          class="label m-2"
          disabled="disabled"
          [(ngModel)]="models[i][column.value]"
          mask="{{ column.mask }}"
          [dropSpecialCharacters]="false"
        >
          {{ model[column.value] }}
        </span>
        <!--
          <input
            type="text"
            *ngIf="!model.EditMode || (model.EditMode && !column.canEdit)"
            class="label"
            disabled="disabled"
            [(ngModel)]="models[i][column.value]"
            value="{{model[column.value]}}"
            mask="{{column.mask}}"
            [dropSpecialCharacters]="false"
          />
        -->
        <input
          type="{{ column.type }}"
          *ngIf="column.canEdit && model.EditMode"
          [(ngModel)]="models[i][column.value]"
          value="{{ model[column.value] }}"
          mask="{{ column.mask }}"
          [dropSpecialCharacters]="false"
        />
      </td>
    </tr>
  </thead>
</table>
