<app-nav-menu></app-nav-menu>
<div class="container-fluid">
  <div class="row">
    <div
      [ngClass]="{
        'col-md-12 body-content': this.router.url.includes('classic/'),
        'col-sm-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2  body-content': !this.router.url.includes(
          'classic/'
        )
      }"
      class="col-sm-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2 body-content"
      style="margin-top: 10px"
    >
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
