import { Component, OnInit } from "@angular/core";
import { LocationHeader } from "../../../classes/LocationHeader";
import { ApiService } from "../Helpers/ApiService";
import { ApiCallMethod } from "../Helpers/McGillConstant";

@Component({
  selector: "app-rental",
  templateUrl: "./rental.component.html"
})
export class RentalComponent implements OnInit {
  public model: LocationHeader[];

  constructor(private api: ApiService) {}

  ngOnInit() {
    this.api
      .CallWebApi(ApiCallMethod.Get, "LocationHeaders/", true, "", "")
      .then((val: LocationHeader[]) => {
        this.model = val;
      });
  }
}
