import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  model: any = {};
  public lblMessage: string;
  public showMessage: boolean;

  constructor(public router: Router) {
    this.showMessage = false;
    this.lblMessage = '';
  }
}
