import { Component } from '@angular/core';
import { AuthService } from '../Helpers/AuthService';
import { ApiService } from '../Helpers/ApiService';
import { ApiCallMethod } from '../Helpers/McGillConstant';
import { getDOM } from '@angular/platform-browser/src/dom/dom_adapter';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent {
  title = 'app';
  model: any = {};
  public lblMessage: string;
  public showMessage: boolean;
  public requestSent = false;

  constructor(private auth: AuthService, private api: ApiService) {
    localStorage.clear();
  }

  public onSubmit() {

    var offset = new Date().getTimezoneOffset();
    this.setCookie("timezone", offset, 7);

    const password = btoa(this.model.Password);
    this.requestSent = true;
    return this.api
      .CallWebApi(
        ApiCallMethod.Post,
        'Users/Login',
        false,
        'username=' + this.model.Username + '&password=' + password,
        ''
      )
      .then(result => {
        if (result != undefined) {
          localStorage.setItem('TokenInfo', result['value'].token);
          localStorage.setItem('IsClassic', result['value'].isClassic);
          localStorage.setItem('UserId', result['value'].userId);
          localStorage.setItem('FullName', result['value'].fullName);
          localStorage.setItem('ClassicTitle', result['value'].classicTitle);
          this.auth.login();
        }
      })
      .catch(res => {
        this.lblMessage = 'Authentication Failed!';
        this.showMessage = true;
        this.requestSent = false;
      });
  }

  private getDomain() {
    var host = window.location.hostname.split('.');
    if (host.length === 1) {
      return ``;
    }
    return host[host.length - 2] + '.' + host[host.length - 1];
  }

  private setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    let domain = ``;
    if (this.getDomain() !== '') {
      domain = `Domain=.${this.getDomain()}`;
    }
    document.cookie = `${cname}=${cvalue}; ${expires}; ${domain}; path=/; SameSime=None; secure;`;
  }
}
