<h3 *ngIf="RentalReturn.id == 0">Place new rental return</h3>
<h3 *ngIf="RentalReturn.id > 0">View rental return {{ RentalReturn.id }}</h3>
<fieldset class="table">
  <legend>Rental Return Header:</legend>
  <div class="row">
    <div class="col-sm-6 col-md-4">
      <label
        >Rental Return Number : <b>{{ RentalReturn.id }}</b></label
      >
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 col-md-4">
      <label>Rental :</label>
      <select
        name="ddlRental"
        [(ngModel)]="RentalReturn.locationHeader"
        class="longDropdownList"
        (ngModelChange)="ddlRental_SelectedIndexChanged()"
        [disabled]="RentalReturn.id > 0"
      >
        <option *ngFor="let event of lstLocationHeader" [ngValue]="event">
          {{ event.name }}
        </option>
      </select>
    </div>
  </div>
</fieldset>
<fieldset class="table">
  <legend>Rental Return creator infos:</legend>
  <div class="row">
    <div class="col-sm-6 col-md-4">
      <label>Created by: </label>
      <input
        type="text"
        class="extendedTextBox"
        [(ngModel)]="RentalReturn.createdBy.fullName"
        [disabled]="true"
      />
    </div>
    <div class="col-sm-6 col-md-4">
      <label>Date created: </label>
      <input
        type="text"
        class="normalTextBox"
        [(ngModel)]="RentalReturn.dateCreatedString"
        [disabled]="true"
      />
    </div>
  </div>
</fieldset>
<fieldset class="table">
  <legend>Rental Return Detail:</legend>
  <div class="row">
    <div class="col-sm-12" *ngIf="message.length > 0">
      <label [ngClass]="{ 'text-danger': error, 'text-success': !error }">{{
        message
      }}</label>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <table
        id="tblReturnProduct"
        *ngIf="
          RentalReturn.rentalReturnProducts != null &&
          RentalReturn.rentalReturnProducts.length > 0
        "
        class="Grid"
      >
        <tr>
          <th *ngIf="RentalReturn.id > 0"><b>Action</b></th>
          <th><b>Product</b></th>
          <th><b>Tag</b></th>
          <th>
            <b>Returned</b><br />
            <input
              name="chkSelectAll"
              type="checkbox"
              (click)="chkSelectAll_Click()"
              [disabled]="RentalReturn.id > 0"
            />
          </th>
        </tr>

        <tr
          *ngFor="let item of RentalReturn.rentalReturnProducts; let i = index"
        >
          <td *ngIf="RentalReturn.id > 0">
            <i class="fas fa-trash-alt" (click)="delete(i)"></i>
          </td>
          <td>
            <label>{{ item.productName }}</label>
          </td>
          <td>
            <label>{{ item.inventoryItem.description }}</label>
          </td>
          <td class="text-center">
            <input
              name="chkSelectProduct"
              type="checkbox"
              [ngModel]="item.isReturned"
              [disabled]="RentalReturn.id > 0"
            />
          </td>
        </tr>
      </table>

      <!--
        (editFunction)="modifyProductRental($event)"
        (deleteFunction)="deleteProductRental($event)"
      -->
    </div>
  </div>

  <div class="col-sm-4 offset-sm-8 text-right">
    <input
      type="button"
      (click)="Save()"
      value="Save"
      *ngIf="!requestSent && RentalReturn.id == 0"
      class="normalButton"
    />
    <mat-spinner
      class="col-sm-4 offset-sm-10 SmallMat-spinner"
      *ngIf="requestSent"
    ></mat-spinner>
  </div>
</fieldset>
