import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ClientTypeEnum,
  values,
  PaymentModeEnum,
  ApiCallMethod
} from '../Helpers/McGillConstant';
import { Client } from '../../../classes/Client';
import { environment } from '../../environments/environment';
import { AuthService } from '../Helpers/AuthService';
import { Department } from '../../../classes/Department';
import { ClientArea } from '../../../classes/ClientArea';
import { ClientFoapal } from '../../../classes/ClientFoapal';
import { ApiService } from '../Helpers/ApiService';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html'
})
export class ClientComponent implements OnInit {
  public Client: Client;
  public lstClient: Client[];
  public lstDepartment: Department[];
  public lstClientType = values(ClientTypeEnum);
  public lstPaymentMode = values(PaymentModeEnum);
  public lstCheckedDepartment: number[];
  public message = '';
  public error = false;
  public requestSent = false;
  public selectedFoapal: ClientFoapal;
  public add = false;
  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private api: ApiService
  ) {}

  ngOnInit(): void {
    this.api
      .CallWebApi(ApiCallMethod.Get, 'Clients/', true, '', '')
      .then((result: Client[]) => {
        this.lstClient = result;
      });
    this.bindDepartment();
  }

  bindDepartment() {
    this.api
      .CallWebApi(ApiCallMethod.Get, 'Departments/', true, '', '')
      .then((result: Department[]) => {
        this.lstDepartment = result;
      });
  }

  bindFoapal() {
    this.api
      .CallWebApi(
        ApiCallMethod.Get,
        'ClientFoapals/GetAllByClientId/' + this.Client.id,
        true,
        '',
        ''
      )
      .then((result: ClientFoapal[]) => {
        this.Client.foapals = result;
      });
  }

  butAddClient() {
    this.Client = new Client();
    this.lstCheckedDepartment = [];
  }

  butRemoveClient() {
    this.api
      .CallWebApi(
        ApiCallMethod.Delete,
        'Clients/' + this.Client.id,
        true,
        '',
        ''
      )
      .then(result => {
        const index = this.lstClient.indexOf(this.Client);
        this.lstClient.splice(index, 1);
        this.Client = null;
        this.error = false;
        this.message = 'Client removed successfully !';
      })
      .catch(res => {
        this.error = true;
        this.message = 'Client cannot be removed !';
      });
  }

  ddlClients_SelectedChanged(event) {
    this.Client = event;
    this.lstCheckedDepartment = this.lstClient
      .filter(x => x.id == this.Client.id)
      .map(x => x.areas.map(y => y.department_id))[0];
    this.bindFoapal();
  }

  checkEmployeeAreaChange() {
    const lstRemovedItem = this.Client.areas.filter(v => {
      if (this.lstCheckedDepartment.indexOf(v.department_id) < 0) {
        return v.id;
      }
    });

    lstRemovedItem.forEach(item => {
      this.api
        .CallWebApi(
          ApiCallMethod.Delete,
          'ClientAreas/' + item.id,
          true,
          '',
          ''
        )
        .then(result => {
          this.Client.areas = this.Client.areas.filter(v => {
            if (lstRemovedItem.indexOf(v) < 0) {
              return v;
            }
          });
        });
    });

    const lstAdded = this.lstCheckedDepartment.filter(v => {
      return this.Client.areas.map(x => x.department_id).indexOf(v) < 0;
    });

    lstAdded.forEach(item => {
      const clientArea = new ClientArea(this.Client.id, item);
      this.api
        .CallWebApi(ApiCallMethod.Post, 'ClientAreas/', true, clientArea, '')
        .then(result => {
          this.Client.areas.push(clientArea);
        });
    });
  }

  save() {
    this.requestSent = true;
    const valid = this.validateClient();
    if (!valid) {
      this.error = true;
      this.requestSent = false;
      this.message = 'Some fields are missing';
      const fieldset = document.getElementById('fieldsetClient');
      if (fieldset) {
        fieldset.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        });
      }
      return;
    }
    if (this.Client.id == 0) {
      this.api
        .CallWebApi(ApiCallMethod.Post, 'Clients/', true, this.Client, '')
        .then((result: Client) => {
          this.Client = result;
          this.lstClient.push(this.Client);
          this.checkEmployeeAreaChange();
          this.requestSent = false;
          this.error = false;
          this.message = 'Success !';
        })
        .catch(res => {
          this.error = true;
          this.message = 'An error occured !';
          this.requestSent = false;
        });
    } else {
      this.api
        .CallWebApi(
          ApiCallMethod.Put,
          'Clients/' + this.Client.id,
          true,
          this.Client,
          ''
        )
        .then((result: Client) => {
          this.Client = result;
          this.checkEmployeeAreaChange();
          this.error = false;
          this.message = 'Success !';
          this.requestSent = false;
        })
        .catch(res => {
          this.error = true;
          this.message = 'An error occured !';
          this.requestSent = false;
        });
    }
  }

  validateClient(): boolean {
    if (
      !this.Client.name ||
      this.Client.clientType_id <= 0 ||
      !this.Client.street ||
      !this.Client.city ||
      !this.Client.state ||
      !this.Client.postalCode ||
      !this.Client.contactName ||
      !this.Client.officePhone ||
      !this.Client.email
    ) {
      return false;
    }
    return true;
  }

  btnAddClientFoapal() {
    this.selectedFoapal = new ClientFoapal();
    this.selectedFoapal.client_id = this.Client.id;
    this.selectedFoapal.createdBy_id = Number(localStorage.getItem('UserId'));
    this.add = true;
  }

  saveFoapal() {
    if (this.selectedFoapal.id == 0) {
      this.api
        .CallWebApi(
          ApiCallMethod.Post,
          'ClientFoapals/',
          true,
          this.selectedFoapal,
          ''
        )
        .then((result: ClientFoapal) => {
          result['CanEdit'] = true;
          this.Client.foapals.push(result);
          this.requestSent = false;
          this.add = false;
        })
        .catch(res => {
          this.auth.logout();
          this.requestSent = false;
        });
    }
  }
}
