import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { User } from '../../../classes/User';
import { Form } from '../../../classes/Form';
import { environment, configToken } from '../../environments/environment';

@Injectable()
export class AuthService {
  constructor(private http: HttpClient, private router: Router) {
    if (
      localStorage.getItem('TokenInfo') != undefined &&
      location.pathname != '/'
    ) {
      if (localStorage.getItem('TokenInfo') != undefined) {
        this.userAuthenticated = new BehaviorSubject<boolean>(true);
      }
    }
  }
  private userAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);
  public forms: Form[];
  public user: string;
  public isSupplier: boolean;

  get getUserAuthenticated() {
    return this.userAuthenticated.asObservable();
  }

  showData() {
    if (localStorage.getItem('TokenInfo') != undefined) {
      configToken.headers['Authorization'] =
        configToken.headers['Authorization'].substring(
          0,
          configToken.headers['Authorization'].indexOf(' ') + 1
        ) + localStorage.getItem('TokenInfo');
      this.http
        .get<HttpResponse<User>>(
          environment.apiUrl + 'Users/' + localStorage.getItem('UserId'),
          configToken
        )
        .subscribe(
          result => {
            this.forms = result.body.userGroup.forms.filter(obj => obj != null);
            this.user = localStorage.getItem('FullName');
            this.isSupplier = this.user.indexOf('Supplier') > -1;
          },
          error => {
            if (error.status == 401) {
              this.logout();
            }
          }
        );
    }
  }

  login() {
    this.userAuthenticated.next(true);
    configToken.headers['Authorization'] =
      configToken.headers['Authorization'].substring(
        0,
        configToken.headers['Authorization'].indexOf(' ') + 1
      ) + localStorage.getItem('TokenInfo');
    if (localStorage.getItem('IsClassic') == 'true') {
      this.router.navigate(['/classic', '~/Default.aspx']);
    } else {
      this.router.navigate(['/rental']);
    }
  }

  logout() {
    localStorage.clear();
    this.userAuthenticated.next(false);
    this.forms = [];
    this.user = '';
    this.router.navigate(['/']);
    const elem = document.getElementById('divLock');
    if (elem != null) {
      elem.remove();
    }
  }
}
