<div class="row m-0">
  <div class="col-sm-6 offset-sm-3 text-right">
    <div class="text-left"><span>Clients:</span></div>
    <select
      name="ddlClients"
      class="fullWidthListBox"
      size="5"
      [ngModel]="Client"
      (ngModelChange)="ddlClients_SelectedChanged($event)"
    >
      <option *ngFor="let client of lstClient" [ngValue]="client">
        {{ client.name }}
      </option>
    </select>
    <br />
    <input
      type="button"
      class="normalButton"
      (click)="butAddClient()"
      value="Add"
    />
    <input
      *ngIf="Client != undefined"
      type="button"
      class="normalButton"
      (click)="butRemoveClient()"
      value="Remove"
    />
  </div>
</div>
<fieldset id="fieldsetClient" class="col-sm-12" *ngIf="Client != undefined">
  <legend>Client Details:</legend>
  <div class="col-sm-12 p-0" *ngIf="message.length > 0">
    <span [ngClass]="{ 'text-success': !error, 'text-danger': error }">{{
      message
    }}</span>
  </div>
  <div class="col-sm-6 p-0">
    <span
      >Client ID : <b>{{ Client.id }}</b></span
    >
  </div>
  <div class="row">
    <div class="col-sm-6">
      <label>Name :</label>
      <input
        name="txtName"
        type="text"
        [(ngModel)]="Client.name"
        class="longTextBox"
        [ngClass]="{
          invalid: !Client.name && error,
          '': !error
        }"
      />
    </div>
    <div class="col-sm-6">
      <label>Client Type:</label> <br />
      <select
        name="ddlClientType"
        [(ngModel)]="Client.clientType_id"
        [ngClass]="{
          invalid: Client.clientType_id == 0 && error,
          '': !error
        }"
      >
        <option
          *ngFor="let clientType of lstClientType"
          [ngValue]="clientType.value"
        >
          {{ clientType.key }}
        </option>
      </select>
    </div>
    <div class="col-sm-6">
      <label>Street :</label>
      <input
        name="txtSteet"
        type="text"
        [(ngModel)]="Client.street"
        class="longTextBox"
        [ngClass]="{
          invalid: !Client.street && error,
          '': !error
        }"
      />
    </div>
    <div class="col-sm-6">
      <label>City :</label>
      <input
        name="txtCity"
        type="text"
        [(ngModel)]="Client.city"
        class="longTextBox"
        [ngClass]="{
          invalid: !Client.city && error,
          '': !error
        }"
      />
    </div>
    <div class="col-sm-6">
      <label>State/prov :</label>
      <input
        name="txtState"
        type="text"
        [(ngModel)]="Client.state"
        class="longTextBox"
        [ngClass]="{
          invalid: !Client.state && error,
          '': !error
        }"
      />
    </div>
    <div class="col-sm-6">
      <label>Country :</label>
      <input
        name="txtCountry"
        type="text"
        [(ngModel)]="Client.country"
        class="longTextBox"
        [ngClass]="{
          invalid: !Client.country && error,
          '': !error
        }"
      />
    </div>
    <div class="col-sm-6">
      <label>Postal Code :</label>
      <input
        name="txtPostalCode"
        type="text"
        [(ngModel)]="Client.postalCode"
        class="longTextBox"
        [ngClass]="{
          invalid: !Client.postalCode && error,
          '': !error
        }"
      />
    </div>
    <div class="col-sm-6">
      <label>Contact Name :</label>
      <input
        name="txtContactName"
        type="text"
        [(ngModel)]="Client.contactName"
        class="longTextBox"
        [ngClass]="{
          invalid: !Client.contactName && error,
          '': !error
        }"
      />
    </div>
    <div class="col-sm-6">
      <label>Office Phone :</label>
      <input
        name="txtOfficePhone"
        type="tel"
        [(ngModel)]="Client.officePhone"
        class="longTextBox"
        [ngClass]="{
          invalid: !Client.officePhone && error,
          '': !error
        }"
      />
    </div>
    <div class="col-sm-6">
      <label>Cell Phone :</label>
      <input
        name="txtCellPhone"
        type="tel"
        [(ngModel)]="Client.cellPhone"
        class="longTextBox"
      />
    </div>
    <div class="col-sm-6">
      <label>Email :</label>
      <input
        name="txtEmail"
        type="email"
        [(ngModel)]="Client.email"
        class="longTextBox"
        [ngClass]="{
          invalid: !Client.email && error,
          '': !error
        }"
      />
    </div>
    <div class="col-sm-12">
      <label>Notes :</label> <br />
      <textarea
        name="txtNotes"
        type="text"
        [(ngModel)]="Client.notes"
        class="longTextBox"
      ></textarea>
      <br />
    </div>
    <div class="col-sm-12">
      <fieldset class="col-sm-12">
        <legend>Foapal</legend>
        <div class="table">
          <div class="row">
            <div class="col-sm-12">
              <app-grid
                [models]="Client.foapals"
                [lstGridColumn]="[
                  {
                    index: 1,
                    name: 'number',
                    header: 'FOAPAL',
                    readonly: false,
                    mask: '000000-00000-000000-0000-000000-000000',
                    width: 275
                  },
                  {
                    index: 2,
                    name: 'description',
                    header: 'Description',
                    readonly: false
                  }
                ]"
                entity="ClientFoapal"
              ></app-grid>
              <input
                type="button"
                value="Add"
                *ngIf="!add"
                class="normalButton"
                (click)="btnAddClientFoapal()"
              />
            </div>
            <div *ngIf="add" class="col-sm-12">
              <div class="col-sm-6">
                <label>Foapal :</label>
                <input
                  type="text"
                  mask="000000-00000-000000-0000-000000-000000"
                  [(ngModel)]="selectedFoapal.number"
                  name="txtFoapal"
                />
              </div>
              <div class="col-sm-6">
                <label>Description :</label>
                <input
                  type="text"
                  [(ngModel)]="selectedFoapal.description"
                  name="txtDescription"
                />
              </div>
              <div class="col-sm-12 text-right">
                <input
                  type="button"
                  value="Save"
                  class="normalButton"
                  *ngIf="
                    selectedFoapal.number.length == 33 &&
                    selectedFoapal.description.length > 0
                  "
                  (click)="saveFoapal()"
                />
                <input
                  type="button"
                  value="Back"
                  class="normalButton"
                  (click)="add = false"
                />
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="col-sm-12">
      <fieldset class="col-sm-12">
        <legend>Area</legend>
        <div class="row">
          <div class="col-sm-12"><span>Department:</span></div>
          <div class="row col-sm-12">
            <mat-selection-list #list [(ngModel)]="lstCheckedDepartment">
              <mat-list-option
                class="col-sm-6"
                *ngFor="let department of lstDepartment"
                [value]="department.id"
              >
                {{ department.name }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="col-sm-12 text-right">
      <input
        type="button"
        value="Save"
        class="normalButton"
        [ngClass]="{
          'd-none': requestSent,
          '': !requestSent
        }"
        (click)="save()"
      />
      <mat-spinner *ngIf="requestSent"></mat-spinner>
    </div>
  </div>
</fieldset>
