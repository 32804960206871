import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment, configToken } from '../../environments/environment';
import { AuthService } from './AuthService';
import { ApiCallMethod } from './McGillConstant';

@Injectable()
export class ApiService {
  constructor(private http: HttpClient, private auth: AuthService) {}

  CallWebApi(method, url, tokenRequired: boolean, body, query) {
    const promise = new Promise((resolve, reject) => {
      if (method == ApiCallMethod.Get) {
        this.http
          .get<HttpResponse<any>>(
            environment.apiUrl + url + query,
            !tokenRequired ? environment.configNoToken : configToken
          )
          .subscribe(
            result => {
              if (result.headers.has('Token')) {
                localStorage.setItem('TokenInfo', result.headers.get('token'));
                configToken.headers['Authorization'] =
                  configToken.headers['Authorization'].substring(
                    0,
                    configToken.headers['Authorization'].indexOf(' ') + 1
                  ) + localStorage.getItem('TokenInfo');
              }
              resolve(result.body);
            },
            error => {
              if (error.status == 401) {
                this.auth.logout();
              } else {
                reject(error);
              }
            }
          );
      } else if (method == ApiCallMethod.Post) {
        this.http
          .post<any>(
            environment.apiUrl + url + query,
            body,
            !tokenRequired ? environment.configNoToken : configToken
          )
          .subscribe(
            (result: HttpResponse<any>) => {
              if (result['headers'] != null) {
                if (result.headers.has('Token')) {
                  localStorage.setItem(
                    'TokenInfo',
                    result.headers.get('token')
                  );
                  configToken.headers['Authorization'] =
                    configToken.headers['Authorization'].substring(
                      0,
                      configToken.headers['Authorization'].indexOf(' ') + 1
                    ) + localStorage.getItem('TokenInfo');
                }
              }
              resolve(result.body ? result.body : result);
            },
            error => {
              if (error.status == 401) {
                this.auth.logout();
              } else {
                reject(error);
              }
            }
          );
      } else if (method == ApiCallMethod.Put) {
        this.http
          .put<HttpResponse<any>>(
            environment.apiUrl + url + query,
            body,
            !tokenRequired ? environment.configNoToken : configToken
          )
          .subscribe(
            result => {
              if (result.headers.has('Token')) {
                localStorage.setItem('TokenInfo', result.headers.get('token'));
                configToken.headers['Authorization'] =
                  configToken.headers['Authorization'].substring(
                    0,
                    configToken.headers['Authorization'].indexOf(' ') + 1
                  ) + localStorage.getItem('TokenInfo');
              }
              resolve(result.body);
            },
            error => {
              if (error.status == 401) {
                this.auth.logout();
              } else {
                reject(error);
              }
            }
          );
      } else if (method == ApiCallMethod.Delete) {
        this.http
          .delete<HttpResponse<any>>(
            environment.apiUrl + url + query,
            !tokenRequired ? environment.configNoToken : configToken
          )
          .subscribe(
            result => {
              if (result.headers.has('Token')) {
                localStorage.setItem('TokenInfo', result.headers.get('token'));
                configToken.headers['Authorization'] =
                  configToken.headers['Authorization'].substring(
                    0,
                    configToken.headers['Authorization'].indexOf(' ') + 1
                  ) + localStorage.getItem('TokenInfo');
              }
              resolve(result.body);
            },
            error => {
              if (error.status == 401) {
                this.auth.logout();
              } else {
                reject(error);
              }
            }
          );
      }
    });
    return promise;
  }
}
