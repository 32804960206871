import { Product } from './Product';
import { InventoryItem } from './InventoryItem';

export class LocationItem {
  id: number;
  isActive: boolean;
  product: Product;
  product_id: number;
  productName: string;
  inventoryItem_id: number;
  inventoryItem: InventoryItem;
  locationDetail_id: number;
  createdBy_id: number;
  lstInventoryItem: InventoryItem[];

  constructor() {
    this.id = 0;
    this.isActive = true;
    this.product = new Product();
    this.product_id = 0;
    this.productName = '';
    this.inventoryItem_id = 0;
    this.locationDetail_id = 0;
  }
}
