import { Component, OnInit } from "@angular/core";
import { RentalReturn } from "../../../classes/RentalReturn";
import { ApiService } from "../Helpers/ApiService";
import { ApiCallMethod } from "../Helpers/McGillConstant";

@Component({
  selector: "app-rentalReturn",
  templateUrl: "./rentalReturn.component.html"
})
export class RentalReturnComponent implements OnInit {
  public model: RentalReturn[];

  constructor(private api: ApiService) {}

  ngOnInit(): void {
    this.api
      .CallWebApi(ApiCallMethod.Get, "RentalReturns/", true, "", "")
      .then((result: RentalReturn[]) => {
        this.model = result;
      });
  }
}
