import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RentalReturn } from '../../../classes/RentalReturn';
import { LocationHeader } from '../../../classes/LocationHeader';
import { LocationItem } from '../../../classes/LocationItem';
import { RentalReturnProduct } from '../../../classes/RentalReturnProduct';
import { ApiCallMethod } from '../Helpers/McGillConstant';
import { ApiService } from '../Helpers/ApiService';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-rentalReturnDetail',
  templateUrl: './rentalReturnDetail.component.html'
})
export class RentalReturnDetailComponent implements OnInit {
  public RentalReturn: RentalReturn;
  public lstLocationHeader: LocationHeader[] = [];
  public lstLocationItem: LocationItem[];
  public model: any[] = [];
  public error: boolean;
  public message = '';
  public id: number;
  public requestSent = false;

  constructor(private route: ActivatedRoute, private api: ApiService) {
    this.RentalReturn = new RentalReturn();
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params.id;
      if (this.id != 0) {
        // call API
        this.api
          .CallWebApi(
            ApiCallMethod.Get,
            'RentalReturns/' + this.id,
            true,
            '',
            ''
          )
          .then((result: RentalReturn) => {
            this.RentalReturn = result;
            this.RentalReturn.rentalReturnProducts = [];
            this.lstLocationHeader.push(this.RentalReturn.locationHeader);
            this.BindRentalReturnProduct();
          });
      } else {
        this.RentalReturn = new RentalReturn();
        this.RentalReturn.dateCreated = new Date();
        this.RentalReturn.dateCreatedString = this.RentalReturn.dateCreated
          .toISOString()
          .split('T')[0];
        this.RentalReturn.createdBy.fullName = localStorage.getItem('FullName');
        this.RentalReturn.createdBy_id = Number(localStorage.getItem('UserId'));
        this.BindLocationHeaders();
      }
    });
  }

  BindLocationHeaders() {
    this.api
      .CallWebApi(
        ApiCallMethod.Get,
        'LocationHeaders/GetNotReturned',
        true,
        '',
        ''
      )
      .then((result: LocationHeader[]) => {
        this.lstLocationHeader = result;
        if (
          this.lstLocationHeader.length > 0 &&
          this.RentalReturn.locationHeader_id == 0
        ) {
          this.RentalReturn.locationHeader = this.lstLocationHeader[0];
          this.RentalReturn.locationHeader_id = this.lstLocationHeader[0].id;
          this.BindLocationItem(this.RentalReturn.locationHeader_id);
        } else {
          this.BindRentalReturnProduct();
        }
      });
  }

  BindLocationItem(LocationHeader_id) {
    this.RentalReturn.rentalReturnProducts = [];
    this.api
      .CallWebApi(
        ApiCallMethod.Get,
        'LocationItems/GetByLocationHeaderId/' + LocationHeader_id,
        true,
        '',
        ''
      )
      .then((result: LocationItem[]) => {
        result.forEach(res => {
          const rentalReturnProduct = new RentalReturnProduct();
          rentalReturnProduct.assignFieldsFromLocationItem(res);
          rentalReturnProduct.createdBy_id = this.RentalReturn.createdBy_id;
          rentalReturnProduct.createdBy = this.RentalReturn.createdBy;
          this.RentalReturn.rentalReturnProducts.push(rentalReturnProduct);
        });
      });
  }

  BindRentalReturnProduct() {
    this.api
      .CallWebApi(
        ApiCallMethod.Get,
        'RentalReturnProducts/GetByRentalReturnId/' + this.id,
        true,
        '',
        ''
      )
      .then((result: RentalReturnProduct[]) => {
        this.RentalReturn.rentalReturnProducts = result;
        this.RentalReturn.rentalReturnProducts.forEach(res => {
          res.isReturned = true;
        });
      });
  }

  ddlRental_SelectedIndexChanged() {
    this.BindLocationItem(this.RentalReturn.locationHeader_id);
  }

  Save() {
    this.requestSent = true;
    this.message = '';
    if (this.id > 0) {
      this.api
        .CallWebApi(
          ApiCallMethod.Put,
          'RentalReturns/' + this.id,
          true,
          this.RentalReturn,
          ''
        )
        .then((result: RentalReturn) => {
          this.message = 'RentalReturn saved successfully !';
          this.error = false;
          this.requestSent = false;
        })
        .catch(res => {
          this.message = 'RentalReturn has not been saved !';
          this.error = true;
          this.requestSent = false;
        });
    } else {
      this.api
        .CallWebApi(
          ApiCallMethod.Post,
          'RentalReturns/',
          true,
          this.RentalReturn,
          ''
        )
        .then((result: RentalReturn) => {
          this.RentalReturn.id = result.id;
          this.RentalReturn.rentalReturnProducts.forEach(res => {
            res.rentalReturn_id = result.id;
          });
          this.api
            .CallWebApi(
              ApiCallMethod.Post,
              'RentalReturnProducts/SaveList/',
              true,
              this.RentalReturn.rentalReturnProducts,
              ''
            )
            // tslint:disable-next-line: no-shadowed-variable
            .then((result: RentalReturnProduct[]) => {
              this.RentalReturn.rentalReturnProducts = result;
              this.message = 'RentalReturn saved successfully !';
              this.error = false;
              this.requestSent = false;
            })
            .catch(res => {
              this.message = 'RentalReturn has not been saved !';
              this.error = true;
              this.requestSent = false;
            });
        })
        .catch(res => {
          this.message = 'RentalReturn has not been saved !';
          this.error = true;
          this.requestSent = false;
        });
    }
  }

  chkSelectAll_Click() {
    this.RentalReturn.rentalReturnProducts.forEach(res => {
      res.isReturned = !res.isReturned;
    });
  }

  checkIfProduct() {
    return (
      this.RentalReturn.rentalReturnProducts.filter(x => x.isReturned).length >
      0
    );
  }

  delete(index) {
    this.requestSent = true;
    this.message = '';
    this.api
      .CallWebApi(
        ApiCallMethod.Delete,
        'RentalReturnProducts/' +
          this.RentalReturn.rentalReturnProducts[index].id,
        true,
        '',
        ''
      )
      .then(result => {
        this.RentalReturn.rentalReturnProducts.splice(index, 1);
        this.message = 'Rental return product has been deleted successfully !';
        this.error = false;
        this.requestSent = false;
      })
      .catch(res => {
        this.message = 'Rental return product has not been deleted !';
        this.error = true;
        this.requestSent = false;
      });
  }
}
