import { Component, OnInit, ViewChild } from '@angular/core';
import { Client } from '../../../classes/Client';
import { Product } from '../../../classes/Product';
import { ProductCategory } from '../../../classes/ProductCategory';
import { ProductSubCategory } from '../../../classes/ProductSubCategory';
import { LocationHeader } from '../../../classes/LocationHeader';
import { ActivatedRoute } from '@angular/router';
import { LocationDetail } from '../../../classes/LocationDetail';
import {
  RentalStatusEnum,
  values,
  PaymentModeEnum,
  ApiCallMethod
} from '../Helpers/McGillConstant';
import { ClientFoapal } from '../../../classes/ClientFoapal';
import { InventoryItem } from '../../../classes/InventoryItem';
import { LocationItem } from '../../../classes/LocationItem';
import { SubletItem } from '../../../classes/SubletItem';
import { Supplier } from '../../../classes/Supplier';
import { Campus } from '../../../classes/Campus';
import { Sector } from '../../../classes/Sector';
import { Building } from '../../../classes/Building';
import { Floor } from '../../../classes/Floor';
import { Room } from '../../../classes/Room';
import { GridComponent } from '../gridComponent/grid.component';
import { ApiService } from '../Helpers/ApiService';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-rentalDetail',
  templateUrl: './rentalDetail.component.html'
})
export class RentalDetailComponent implements OnInit {
  public LocationHeader: LocationHeader;
  public selectedCategory: ProductCategory;
  public selectedSubCategory: ProductSubCategory;
  public selectedProduct: Product;
  public qtyAvailableInv = 0;
  public qtyAvailableSublet = 0;
  public qtyInvRequested = 0;
  public qtySubletRequested = 0;
  public lstClient: Client[];
  public lstPaymentMode = values(PaymentModeEnum);
  public lstRentalStatus = values(RentalStatusEnum);
  public paymentModeEnum = PaymentModeEnum;
  public lstCategory: ProductCategory[];
  public lstSubCategory: ProductSubCategory[];
  public lstProduct: Product[];
  public lstFoapal: ClientFoapal[];
  public lstCampus: Campus[];
  public lstSector: Sector[];
  public lstBuilding: Building[];
  public lstWing: string[];
  public lstFloor: Floor[];
  public lstRoom: Room[];
  public model: any[] = [];
  public showInventoryItemGrid = false;
  public error: boolean;
  public message = '';
  public id: number;
  public requestSent = false;
  public firstCall = true;
  @ViewChild(GridComponent) GridComponent: GridComponent;

  constructor(private route: ActivatedRoute, private api: ApiService) {
    this.LocationHeader = new LocationHeader();
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params.id;
      if (this.id != 0) {
        // call API
        this.api
          .CallWebApi(
            ApiCallMethod.Get,
            'LocationHeaders/' + this.id,
            true,
            '',
            ''
          )
          .then((result: LocationHeader) => {
            this.LocationHeader = result;
            this.canShowInventoryItemGrid();
          });
      } else {
        this.LocationHeader = new LocationHeader();
        this.LocationHeader.dateCreated = new Date();
        this.LocationHeader.dateCreatedString = this.LocationHeader.dateCreated
          .toISOString()
          .split('T')[0];
        this.LocationHeader.dateFrom = new Date();
        this.LocationHeader.createdBy.fullName = localStorage.getItem(
          'FullName'
        );
        this.LocationHeader.createdBy_id = Number(
          localStorage.getItem('UserId')
        );
      }
    });
    this.BindCategory();
    this.BindClient();
    this.BindCampus();
    this.firstCall = false;
  }

  BindCategory() {
    this.api
      .CallWebApi(
        ApiCallMethod.Get,
        'ProductCategories?forRental=true',
        true,
        '',
        ''
      )
      .then((result: ProductCategory[]) => {
        this.lstCategory = result;
        if (this.lstCategory.length > 0) {
          this.selectedCategory = this.lstCategory[0];
          this.BindSubCategory(this.lstCategory[0]);
        }
      });
  }

  BindSubCategory(productCategory) {
    this.api
      .CallWebApi(
        ApiCallMethod.Get,
        'ProductSubCategories/GetByProductCategoryId/' +
          productCategory.id +
          '?forRental=true',
        true,
        '',
        ''
      )
      .then((result: ProductSubCategory[]) => {
        this.lstSubCategory = result;
        if (this.lstSubCategory.length > 0) {
          this.selectedSubCategory = this.lstSubCategory[0];
          this.BindProduct(this.lstSubCategory[0]);
        }
      });
  }

  BindProduct(productSubCategory) {
    this.api
      .CallWebApi(
        ApiCallMethod.Get,
        'Products/GetByProductSubCategoryId/' +
          productSubCategory.id +
          '?forRental=true',
        true,
        '',
        ''
      )
      .then((result: Product[]) => {
        this.lstProduct = result;
        this.selectedProduct = this.lstProduct[0];
        this.GetQtyAvailable(null);
      });
  }

  BindClient() {
    this.api
      .CallWebApi(ApiCallMethod.Get, 'Clients/', true, '', '')
      .then((result: Client[]) => {
        this.lstClient = result;
        if (this.LocationHeader.client_id == 0) {
          this.LocationHeader.client_id = this.lstClient[0]
            ? this.lstClient[0].id
            : 0;
        }
        this.BindClientFoapal();
      });
  }

  BindClientFoapal() {
    this.api
      .CallWebApi(
        ApiCallMethod.Get,
        'ClientFoapals/GetAllByClientId/' + this.LocationHeader.client_id,
        true,
        '',
        ''
      )
      .then((result: ClientFoapal[]) => {
        this.lstFoapal = result;
      });
  }

  BindCampus() {
    this.api
      .CallWebApi(ApiCallMethod.Get, 'Campus/', true, '', '')
      .then((result: Campus[]) => {
        this.lstCampus = result;
        if (
          this.LocationHeader.campus_id == 0 ||
          this.lstCampus.filter(x => x.id == this.LocationHeader.campus_id)
            .length == 0
        ) {
          this.LocationHeader.campus_id = this.lstCampus[0]
            ? this.lstCampus[0].id
            : 0;
        }
        this.BindSector(this.LocationHeader.campus_id);
      });
  }

  BindSector(campus_id) {
    this.api
      .CallWebApi(
        ApiCallMethod.Get,
        'Sectors/GetByCampusId/' + campus_id,
        true,
        '',
        ''
      )
      .then((result: Sector[]) => {
        this.lstSector = result;
        if (
          this.LocationHeader.sector_id == 0 ||
          this.lstSector.filter(x => x.id == this.LocationHeader.sector_id)
            .length == 0
        ) {
          this.LocationHeader.sector_id = this.lstSector[0]
            ? this.lstSector[0].id
            : 0;
        }
        this.BindBuilding(this.LocationHeader.sector_id);
      });
  }

  BindBuilding(sector_id) {
    this.api
      .CallWebApi(
        ApiCallMethod.Get,
        'Buildings/GetBySectorId/' + sector_id,
        true,
        '',
        ''
      )
      .then((result: Building[]) => {
        this.lstBuilding = result;
        if (
          this.LocationHeader.building_id == 0 ||
          this.lstBuilding.filter(x => x.id == this.LocationHeader.building_id)
            .length == 0
        ) {
          this.LocationHeader.building_id = this.lstBuilding[0]
            ? this.lstBuilding[0].id
            : 0;
        }
        this.BindWing(this.LocationHeader.building_id);
      });
  }

  BindWing(building_id) {
    this.api
      .CallWebApi(
        ApiCallMethod.Get,
        'Rooms/GetWingByBuilding/' + building_id,
        true,
        '',
        ''
      )
      .then((result: string[]) => {
        this.lstWing = result;
        if (
          this.LocationHeader.wing == '' ||
          this.lstWing.filter(x => x == this.LocationHeader.wing).length == 0
        ) {
          this.LocationHeader.wing = this.lstWing[0] ? this.lstWing[0] : '';
        }
        if (this.firstCall) {
          this.BindFloor(
            this.LocationHeader.wing,
            this.LocationHeader.building_id
          );
        }
      });
  }

  BindFloor(wing, building_id) {
    if (wing != null && building_id) {
      this.api
        .CallWebApi(
          ApiCallMethod.Get,
          'Floors/GetByBuildingId/' + building_id + '?wing=' + wing,
          true,
          '',
          ''
        )
        .then((result: Floor[]) => {
          this.lstFloor = result;
          if (
            this.LocationHeader.floor_id == 0 ||
            this.lstFloor.filter(x => x.id == this.LocationHeader.floor_id)
              .length == 0
          ) {
            this.LocationHeader.floor_id = this.lstFloor[0]
              ? this.lstFloor[0].id
              : 0;
          }
          this.BindRoom(this.LocationHeader.wing, this.LocationHeader.floor_id);
        });
    } else {
      this.lstFloor = null;
      this.lstRoom = null;
    }
  }

  BindRoom(wing, floor_id) {
    if (floor_id) {
      this.api
        .CallWebApi(
          ApiCallMethod.Get,
          'Rooms/GetByFloorId/' + floor_id + '?wing=' + wing,
          true,
          '',
          ''
        )
        .then((result: Room[]) => {
          this.lstRoom = result;
          if (
            this.LocationHeader.room_id == 0 ||
            this.lstRoom.filter(x => x.id == this.LocationHeader.room_id)
              .length == 0
          ) {
            this.LocationHeader.room_id = this.lstRoom[0]
              ? this.lstRoom[0].id
              : 0;
          }
          this.BindRoomDetail(this.LocationHeader.room_id);
        });
    }
  }

  BindRoomDetail(room_id) {
    this.api
      .CallWebApi(ApiCallMethod.Get, 'Rooms/' + room_id, true, '', '')
      .then((result: Room) => {
        const room = result;
        if (room != null) {
          this.LocationHeader.roomUseDescription = room.roomUseDesc;
          this.LocationHeader.roomArea = Number(room.roomArea.toFixed(2));
          this.LocationHeader.seating = room.seating;
        }
      });
  }

  AddProductToRental() {
    this.error = false;
    if (
      this.LocationHeader.locationDetailDTOs != null &&
      this.LocationHeader.locationDetailDTOs
        .map(x => x.productName)
        .includes(this.selectedProduct.name)
    ) {
      this.error = true;
      this.message = 'Cannot add same product to the event !';
    } else {
      const location = new LocationDetail(
        0,
        new Date(),
        Number(localStorage.getItem('UserId')),
        this.selectedProduct,
        this.qtyInvRequested + this.qtySubletRequested,
        // this.qtyProduct,
        this.qtyInvRequested,
        this.qtySubletRequested,
        0,
        this.id
      );
      location['CanEdit'] = true;
      if (this.LocationHeader.locationDetailDTOs == null) {
        this.LocationHeader.locationDetailDTOs = [location];
      } else {
        this.LocationHeader.locationDetailDTOs.push(location);
      }
      if (this.qtyInvRequested > 0) {
        this.inventoryItems(this.selectedProduct.id, this.qtyInvRequested);
      }
      if (this.qtySubletRequested > 0) {
        this.subletItems(this.selectedProduct.id, this.qtySubletRequested);
      }
      this.LocationHeader.locationDetailDTOs = JSON.parse(
        JSON.stringify(this.LocationHeader.locationDetailDTOs)
      );

      this.qtyAvailableInv -= this.qtyInvRequested;
      this.qtyAvailableSublet -= this.qtyAvailableSublet;
    }
  }

  modifyProductRental(index) {
    const location = this.LocationHeader.locationDetailDTOs[index];
    const productId = this.LocationHeader.locationDetailDTOs[index].product_id;
    const locationItems = this.LocationHeader.locationItems.filter(
      x => x.product_id == productId
    );
    if (location != null) {
      this.GetQtyAvailable(null);
      location.qtyRequested =
        Number(location.qtyFromInventory) + Number(location.qtyFromSublet);

      if (locationItems.length != location.qtyFromInventory) {
        const oldQtyFromInventory = locationItems.length;
        if (oldQtyFromInventory < location.qtyFromInventory) {
          this.createLocationItem(
            locationItems[0].lstInventoryItem,
            location.qtyFromInventory - oldQtyFromInventory
          );
        } else {
          const array = this.LocationHeader.locationItems.filter(
            x => x.product_id == location.product_id
          );
          for (let i = array.length; i > location.qtyFromInventory; i--) {
            // tslint:disable-next-line: no-shadowed-variable
            const index = this.LocationHeader.locationItems.indexOf(
              array[i - 1]
            );
            this.LocationHeader.locationItems[index].isActive = false;
            this.canShowInventoryItemGrid();
            // this.LocationHeader.locationItems.splice(index, 1);
          }
        }
      }

      // Save modif
      this.api
        .CallWebApi(
          ApiCallMethod.Put,
          'LocationDetails/' + location.id,
          true,
          location,
          ''
        )
        .then((result: LocationDetail) => {});

      this.LocationHeader.locationDetailDTOs = JSON.parse(
        JSON.stringify(this.LocationHeader.locationDetailDTOs)
      );

      // if (oldQtyFromSublet > 0) {
      //   this.subletItems(location.product_id, oldQtyFromSublet);
      // } else {
      //   let array = this.LocationHeader.subletItems.filter(
      //     x => x.product_id == location.product_id
      //   );
      //   for (let i = array.length; i > location.qtyFromSublet; i--) {
      //     let index = this.LocationHeader.subletItems.indexOf(
      //       array[i - 1]
      //     );
      //     this.LocationHeader.subletItems.splice(index, 1);
      //   }
      // }
    }
  }

  deleteProductRental(index) {
    const productId = this.LocationHeader.locationDetailDTOs[index].product_id;

    for (let i = this.LocationHeader.locationItems.length - 1; i >= 0; i--) {
      if (this.LocationHeader.locationItems[i].product_id == productId) {
        this.LocationHeader.locationItems.splice(i, 1);
      }
    }

    if (this.LocationHeader.locationItems.length == 0) {
      this.LocationHeader.locationItems = null;
    }

    for (let i = this.LocationHeader.subletItems.length - 1; i >= 0; i--) {
      if (this.LocationHeader.subletItems[i].product_id == productId) {
        this.LocationHeader.subletItems.splice(i, 1);
      }
    }

    if (this.LocationHeader.subletItems.length == 0) {
      this.LocationHeader.subletItems = null;
    }

    this.canShowInventoryItemGrid();
  }

  validateProductRental(index) {
    const avail = this.LocationHeader.locationDetailDTOs[index]
      .qtyFromInventory;
    const currentLocationQty = this.LocationHeader.locationItems.filter(
      x =>
        x.product_id ==
          this.LocationHeader.locationDetailDTOs[index].product_id && x.isActive
    ).length;
    const sublet = this.LocationHeader.locationDetailDTOs[index].qtyFromSublet;
    this.GetQtyAvailable(() => {
      if (
        avail - currentLocationQty <= this.qtyAvailableInv &&
        sublet <= this.qtyAvailableSublet
      ) {
        this.GridComponent.saveEdit(index);
      } else {
        alert(
          'Qty from inventory must be lower/equal than ' +
            (avail - currentLocationQty) +
            ' and Qty from sublet must be lower/equal than ' +
            this.qtyAvailableSublet
        );
      }
    });
  }

  Save() {
    this.message = '';
    if (
      this.LocationHeader.locationDetailDTOs == null ||
      (<any>this.LocationHeader.locationDetailDTOs).filter(
        x => x.EditMode == true
      ).length == 0
    ) {
      this.requestSent = true;
      if (
        this.LocationHeader.paymentMode_id != this.paymentModeEnum.FOAPAL &&
        this.LocationHeader.clientFoapal_id > 0
      ) {
        this.LocationHeader.clientFoapal_id = null;
      }
      if (this.id > 0) {
        this.api
          .CallWebApi(
            ApiCallMethod.Put,
            'LocationHeaders/' + this.id,
            true,
            this.LocationHeader,
            ''
          )
          .then((result: LocationHeader) => {
            this.LocationHeader.id = result.id;
            this.id = this.LocationHeader.id;
            this.message = 'LocationHeader saved successfully !';
            this.error = false;
            this.requestSent = false;
          })
          .catch(res => {
            this.message = 'LocationHeader has not been saved !';
            this.error = true;
            this.requestSent = false;
          });
      } else {
        this.api
          .CallWebApi(
            ApiCallMethod.Put,
            'LocationHeaders/',
            true,
            this.LocationHeader,
            ''
          )
          .then((result: LocationHeader) => {
            this.LocationHeader.id = result.id;
            this.id = this.LocationHeader.id;
            this.message = 'LocationHeader saved successfully !';
            this.error = false;
            this.requestSent = false;
          })
          .catch(res => {
            this.message = 'LocationHeader has not been saved !';
            this.error = true;
            this.requestSent = false;
          });
      }
    } else {
      this.error = true;
      this.message = 'You cannot save when a row is in edit mode !';
    }
  }

  ddlClient_SelectedIndexChanged() {
    this.BindClientFoapal();
  }

  ddlCampus_SelectedIndexChanged() {
    this.BindSector(this.LocationHeader.campus_id);
  }

  ddlSector_SelectedIndexChanged() {
    this.BindBuilding(this.LocationHeader.sector_id);
  }

  ddlBuilding_SelectedIndexChanged() {
    this.BindWing(this.LocationHeader.building_id);
  }

  ddlWing_SelectedIndexChanged() {
    this.BindFloor(this.LocationHeader.wing, this.LocationHeader.building_id);
  }

  ddlFloor_SelectedIndexChanged() {
    this.BindRoom(this.LocationHeader.wing, this.LocationHeader.floor_id);
  }

  ddlRoom_SelectedIndexChanged() {
    this.BindRoomDetail(this.LocationHeader.room_id);
  }

  ddlProduct_SelectedIndexChanged() {
    this.GetQtyAvailable(null);
  }

  GetQtyAvailable(callback) {
    let compteur = 0;

    this.api
      .CallWebApi(
        ApiCallMethod.Get,
        'InventoryItems/GetByProductIdForRental/' +
          this.selectedProduct.id +
          '?fromDate=' +
          this.LocationHeader.dateFrom,
        true,
        '',
        ''
      )
      .then((result: number) => {
        compteur++;
        this.qtyAvailableInv = result;
        if (callback != null && compteur == 1) {
          callback();
        }
      });

    // Get Sublet Qty Available
    // this.http
    //   .get<number>(
    //     environment.apiUrl +
    //       "InventoryItems/GetByProductIdForRental/" +
    //       this.selectedProduct.id,
    //     environment.configToken
    //   )
    //   .subscribe(
    //     result => {
    //       compteur++;
    //       this.qtyAvailableSublet = result;
    //     },
    //     error => {
    //       console.log(error.message);
    //     }
    //   );
  }

  inventoryItems(productId, qtyFromInventory) {
    this.api
      .CallWebApi(
        ApiCallMethod.Get,
        'InventoryItems/GetByProductId/' + productId + '?includeInactive=true',
        true,
        '',
        ''
      )
      .then((result: InventoryItem[]) => {
        this.createLocationItem(result, qtyFromInventory);
      });
  }

  canShowInventoryItemGrid() {
    const LocationItems = this.LocationHeader.locationDetailDTOs.map(
      x => x.locationItems
    );

    const lstResult: LocationItem[] = [];

    LocationItems.forEach(res => {
      const temp = res.filter(x => x.isActive);
      if (temp.length > 0) {
        temp.forEach(item => {
          lstResult.push(item);
        });
      }
    });

    const ContainActiveItem = lstResult.length > 0;
    this.showInventoryItemGrid = LocationItems != null && ContainActiveItem;
  }

  createLocationItem(result, qtyFromInventory) {
    const locationDetail = this.LocationHeader.locationDetailDTOs.find(
      x => x.product_id == this.selectedProduct.id
    );
    for (let i = 0; i < qtyFromInventory; i++) {
      const locationItems = new LocationItem();
      locationItems.product = this.selectedProduct;
      locationItems.product_id = this.selectedProduct.id;
      locationItems.productName = this.selectedProduct.name;
      locationItems.createdBy_id = Number(localStorage.getItem('UserId'));
      locationItems.lstInventoryItem = result;
      if (locationDetail != null) {
        if (locationDetail.locationItems == null) {
          locationDetail.locationItems = [locationItems];
        } else {
          locationDetail.locationItems.push(locationItems);
        }
      }
      this.canShowInventoryItemGrid();
    }
  }

  subletItems(productId, qtyFromSublet) {
    this.api
      .CallWebApi(
        ApiCallMethod.Get,
        'Suppliers/GetAllByProductId/' + productId,
        true,
        '',
        ''
      )
      .then((result: Supplier[]) => {
        for (let i = 0; i < qtyFromSublet; i++) {
          const subletItem = new SubletItem();
          subletItem.product = this.selectedProduct;
          subletItem.product_id = this.selectedProduct.id;
          subletItem.productName = this.selectedProduct.name;
          subletItem.lstSuppliers = result;
          if (this.LocationHeader.subletItems == null) {
            this.LocationHeader.subletItems = [subletItem];
          } else {
            this.LocationHeader.subletItems.push(subletItem);
          }
        }
      });
  }

  validateSameItem(event, index: number) {
    if (
      this.LocationHeader.locationItems.filter(
        x => x != undefined && x.inventoryItem_id == event
      ).length > 1
    ) {
      this.LocationHeader.locationItems[index].inventoryItem_id = -1;
      alert('Cannot select 2 times the same inventory item !');
    }
  }
}
