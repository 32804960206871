import { Client } from './Client';
import { User } from './User';
import { LocationDetail } from './LocationDetail';
import { PaymentMode } from './PaymentMode';
import { LocationItem } from './LocationItem';
import { SubletItem } from './SubletItem';

export class LocationHeader {
  id: number;
  isActive: boolean;
  name: string;
  client: Client;
  clientName: string;
  client_id: number;
  dateFrom: Date;
  dateFromString: string;
  dateTo: Date;
  dateToString: string;
  createdBy: User;
  createdBy_id: number;
  dateCreated: Date;
  dateCreatedString: string;
  lastUpdatedBy: User;
  lastUpdatedBy_id: number;
  lastUpdatedDate: Date;
  status_id: number;
  paymentMode_id: number;
  paymentMode: PaymentMode;
  clientFoapal_id: number;
  campus_id: number;
  sector_id: number;
  building_id: number;
  wing: string;
  floor_id: number;
  room_id: number;
  roomUseDescription: string;
  roomArea: number;
  seating: number;
  locationDetailDTOs: LocationDetail[];
  locationItems: LocationItem[];
  subletItems: SubletItem[];

  constructor() {
    this.id = 0;
    this.isActive = true;
    this.name = '';
    this.client = new Client();
    this.client_id = 0;
    this.clientFoapal_id = null;
    this.dateFrom = null;
    this.dateTo = null;
    this.createdBy_id = 0;
    this.createdBy = new User();
    this.dateCreated = new Date();
    this.lastUpdatedBy_id = null;
    this.status_id = 1;
    this.paymentMode = new PaymentMode();
    this.paymentMode_id = 1;
    this.campus_id = null;
    this.sector_id = null;
    this.building_id = null;
    this.wing = '';
    this.floor_id = null;
    this.room_id = null;
    this.locationDetailDTOs = [];
  }
}
