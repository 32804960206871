import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
export function getBaseUrl() {
    return document.getElementsByTagName('base')[0].href;
}
// const providers = [
//   { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
//   { provide: 'API_URL', useValue: { url: 'http://localhost:40050/v1/' } },
//   { provide: 'CLASSIC_URL', useValue: { url: 'https://localhost:44300/' } }
// ];
var providers = [];
if (environment.production) {
    enableProdMode();
    providers = [
        { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
        {
            provide: 'API_URL',
            useValue: { url: 'http://api.usids.devcolab.ca/v1/' }
        },
        {
            provide: 'CLASSIC_URL',
            useValue: { url: 'https://mcgillclassic.devcolab.ca/classic/' }
        }
    ];
}
else {
    providers = [
        { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
        { provide: 'API_URL', useValue: { url: 'http://localhost:40050/v1/' } },
        { provide: 'CLASSIC_URL', useValue: { url: 'https://localhost:44300/' } }
    ];
}
__NgCli_bootstrap_2.platformBrowser(providers)
    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .catch(function (err) { return console.log(err); });
