/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i2 from "@angular/material/progress-spinner";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "@angular/common";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./classic.component";
import * as i7 from "@angular/router";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../Helpers/ApiService";
import * as i10 from "@angular/common/http";
var styles_ClassicComponent = [];
var RenderType_ClassicComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ClassicComponent, data: {} });
export { RenderType_ClassicComponent as RenderType_ClassicComponent };
function View_ClassicComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mx-auto mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i1.View_MatSpinner_0, i1.RenderType_MatSpinner)), i0.ɵdid(1, 49152, null, 0, i2.MatSpinner, [i0.ElementRef, i3.Platform, [2, i4.DOCUMENT], [2, i5.ANIMATION_MODULE_TYPE], i2.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i0.ɵnov(_v, 1).diameter; var currVal_2 = i0.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ClassicComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "col-sm-12 text-center"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ClassicComponent_1)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 0, "iframe", [["id", "objectClassic"], ["name", "objectClassic"], ["style", "width:100%;margin-top:10px"]], [[8, "src", 5]], [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.disableSpinner() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.classicUrl; _ck(_v, 4, 0, currVal_1); }); }
export function View_ClassicComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-classic", [], null, null, null, View_ClassicComponent_0, RenderType_ClassicComponent)), i0.ɵdid(1, 245760, null, 0, i6.ClassicComponent, [i7.ActivatedRoute, i8.DomSanitizer, i9.ApiService, i7.Router, i10.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClassicComponentNgFactory = i0.ɵccf("app-classic", i6.ClassicComponent, View_ClassicComponent_Host_0, {}, {}, []);
export { ClassicComponentNgFactory as ClassicComponentNgFactory };
