import { ProductPricing } from './ProductPricing';

export class Product {
  id: number;
  isActive: boolean;
  name: string;
  unitCost: number;
  supplier_id: number;
  productPricing: ProductPricing;
}
