import { User } from './User';
import { Product } from './Product';
import { LocationItem } from './LocationItem';

export class LocationDetail {
  id: number;
  isActive: boolean;
  dateCreated: Date;
  dateCreatedString: string;
  createdBy: User;
  createdBy_id: number;
  product_id: number;
  productName: string;
  // inventoryItem_id: number;
  qtyRequested: number;
  qtyFromInventory: number;
  qtyFromSublet: number;
  unitCost: number;
  // status_id: number;
  // Status: RentalStatus;
  // supplier_id: number;
  // Supplier: Supplier;
  locationHeader_id: number;
  locationItems: LocationItem[];

  constructor(
    id: number,
    dateCreated: Date,
    createdBy_id: number,
    product: Product,
    qtyRequested: number,
    qtyFromInventory: number,
    qtyFromSublet: number,
    status_id: number,
    locationHeader_id: number
    // inventoryItem_id: number
  ) {
    this.id = id;
    this.isActive = true;
    this.dateCreated = dateCreated;
    this.dateCreatedString = dateCreated.toDateString();
    this.createdBy_id = createdBy_id;
    this.product_id = product.id;
    this.productName = product.name;
    this.qtyRequested = qtyRequested;
    this.qtyFromInventory = qtyFromInventory;
    this.qtyFromSublet = qtyFromSublet;
    this.unitCost = product.unitCost;
    // this.status_id = status_id;
    // this.supplier_id = product.supplier_id;
    this.locationHeader_id = locationHeader_id;
    // this.inventoryItem_id = inventoryItem_id;
  }
}
