import { UserGroup } from './UserGroup';

export class User {
  cellPhone: string;
  createdById: number;
  dateCreated: Date;
  email: string;
  emailNotification: boolean;
  // employee:
  employeeId: number;
  firstName: string;
  id: number;
  isActive: boolean;
  lastName: string;
  mcGillId: number;
  pager: string;
  phone: string;
  profile: object;
  profileId: number;
  supplier: object;
  supplierId: number;
  userGroup: UserGroup;
  userGroupId: number;
  userSupplierAccessLevel: object;
  isClassic: boolean;
  fullName: string;
}
