import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CanActivateRouteGuard } from './Helpers/can-activate-route.guard';
import { AuthService } from './Helpers/AuthService';
import { ApiService } from './Helpers/ApiService';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { ClassicComponent } from './classic/classic.component';
import { LoginComponent } from './login/login.component';
import { RentalComponent } from './rental/rental.component';
import { RentalDetailComponent } from './rental/rentalDetail.component';
import { RentalReturnComponent } from './rentalReturn/rentalReturn.component';
import { RentalReturnDetailComponent } from './rentalReturn/rentalReturnDetail.component';
import { SubletComponent } from './sublet/sublet.component';
import { SubletDetailComponent } from './sublet/subletDetail.component';
import { SubletReceptionComponent } from './subletReception/subletReception.component';
import { SubletReceptionDetailComponent } from './subletReception/subletReceptionDetail.component';
import { ClientComponent } from './client/client.component';
import { GridComponent } from './gridComponent/grid.component';
import { DynamicComponent } from './Helpers/dynamic.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMaskModule } from 'ngx-mask';
import {
  MatInputModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatNativeDateModule,
  MatListModule,
  MatProgressSpinnerModule,
  MAT_DATE_LOCALE
} from '@angular/material';
import { USE_VALUE } from '@angular/core/src/di/injector';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    ClassicComponent,
    LoginComponent,
    RentalComponent,
    RentalDetailComponent,
    RentalReturnComponent,
    RentalReturnDetailComponent,
    SubletComponent,
    SubletDetailComponent,
    SubletReceptionComponent,
    SubletReceptionDetailComponent,
    ClientComponent,
    GridComponent,
    DynamicComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatListModule,
    MatProgressSpinnerModule,
    NgxMaskModule.forRoot(),
    RouterModule.forRoot([
      { path: '', component: LoginComponent, pathMatch: 'full' },
      {
        path: 'classic/:classicUrl',
        component: ClassicComponent,
        canActivate: [CanActivateRouteGuard],
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'rental',
        component: RentalComponent,
        canActivate: [CanActivateRouteGuard],
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'rental/:id',
        component: RentalDetailComponent,
        canActivate: [CanActivateRouteGuard],
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'rentalReturn',
        component: RentalReturnComponent,
        canActivate: [CanActivateRouteGuard],
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'rentalReturn/:id',
        component: RentalReturnDetailComponent,
        canActivate: [CanActivateRouteGuard],
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'sublet',
        component: SubletComponent,
        canActivate: [CanActivateRouteGuard],
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'sublet/:id',
        component: SubletDetailComponent,
        canActivate: [CanActivateRouteGuard],
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'subletReception',
        component: SubletReceptionComponent,
        canActivate: [CanActivateRouteGuard],
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'subletReception/:id',
        component: SubletReceptionDetailComponent,
        canActivate: [CanActivateRouteGuard],
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'client',
        component: ClientComponent,
        canActivate: [CanActivateRouteGuard],
        runGuardsAndResolvers: 'always'
      }
    ],
    {onSameUrlNavigation: 'reload'}),
    BrowserAnimationsModule
  ],
  exports: [RouterModule],
  providers: [
    AuthService,
    ApiService,
    CanActivateRouteGuard,
    { provide: MAT_DATE_LOCALE, useValue: 'fr-CA' }
  ],
  bootstrap: [AppComponent],
  entryComponents: [DynamicComponent, GridComponent]
})
export class AppModule {}
