import { User } from './User';
import { Product } from './Product';
import { LocationUnitEnum } from '../src/app/Helpers/McGillConstant';

export class SubletDetail {
  id: number;
  dateCreated: Date;
  dateCreatedString: string;
  createdBy: User;
  createdBy_id: number;
  product_id: number;
  productName: string;
  qtyRequested: number;
  unitCost: number;
  locationPrice: number;
  status_id: number;
  subletHeader_id: number;
  locationUnit: string;

  constructor(
    id: number,
    dateCreated: Date,
    createdBy_id: number,
    product: Product,
    qtyRequested: number,
    status_id: number,
    subletHeader_id: number
  ) {
    this.id = id;
    this.dateCreated = dateCreated;
    this.dateCreatedString = dateCreated.toDateString();
    this.createdBy_id = createdBy_id;
    this.product_id = product.id;
    this.productName = product.name;
    this.qtyRequested = qtyRequested;
    this.unitCost = product.unitCost;
    this.status_id = status_id;
    this.subletHeader_id = subletHeader_id;
    this.locationPrice = product.productPricing.locationPrice;
    this.locationUnit = LocationUnitEnum[product.productPricing.locationUnit];
  }
}
