import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment, configToken } from '../../environments/environment';
var AuthService = /** @class */ (function () {
    function AuthService(http, router) {
        this.http = http;
        this.router = router;
        this.userAuthenticated = new BehaviorSubject(false);
        if (localStorage.getItem('TokenInfo') != undefined &&
            location.pathname != '/') {
            if (localStorage.getItem('TokenInfo') != undefined) {
                this.userAuthenticated = new BehaviorSubject(true);
            }
        }
    }
    Object.defineProperty(AuthService.prototype, "getUserAuthenticated", {
        get: function () {
            return this.userAuthenticated.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.showData = function () {
        var _this = this;
        if (localStorage.getItem('TokenInfo') != undefined) {
            configToken.headers['Authorization'] =
                configToken.headers['Authorization'].substring(0, configToken.headers['Authorization'].indexOf(' ') + 1) + localStorage.getItem('TokenInfo');
            this.http
                .get(environment.apiUrl + 'Users/' + localStorage.getItem('UserId'), configToken)
                .subscribe(function (result) {
                _this.forms = result.body.userGroup.forms.filter(function (obj) { return obj != null; });
                _this.user = localStorage.getItem('FullName');
                _this.isSupplier = _this.user.indexOf('Supplier') > -1;
            }, function (error) {
                if (error.status == 401) {
                    _this.logout();
                }
            });
        }
    };
    AuthService.prototype.login = function () {
        this.userAuthenticated.next(true);
        configToken.headers['Authorization'] =
            configToken.headers['Authorization'].substring(0, configToken.headers['Authorization'].indexOf(' ') + 1) + localStorage.getItem('TokenInfo');
        if (localStorage.getItem('IsClassic') == 'true') {
            this.router.navigate(['/classic', '~/Default.aspx']);
        }
        else {
            this.router.navigate(['/rental']);
        }
    };
    AuthService.prototype.logout = function () {
        localStorage.clear();
        this.userAuthenticated.next(false);
        this.forms = [];
        this.user = '';
        this.router.navigate(['/']);
        var elem = document.getElementById('divLock');
        if (elem != null) {
            elem.remove();
        }
    };
    return AuthService;
}());
export { AuthService };
