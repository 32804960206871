<h3>Place new sublet</h3>
<fieldset class="table">
  <legend>Sublet Header:</legend>
  <div class="row m-0">
    <div class="col-sm-6 col-md-4">
      <label
        >Sublet Number : <b>{{ SubletHeader.id }}</b></label
      >
    </div>
  </div>
  <div class="row m-0">
    <div class="col-sm-6">
      <label>Date From:</label> <br />
      <mat-form-field
        [ngClass]="{
          invalid: SubletHeader.dateFrom == null && error,
          '': !error
        }"
      >
        <input
          matInput
          [matDatepicker]="picker"
          placeholder="Choose a date"
          [(ngModel)]="SubletHeader.dateFrom"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-sm-6">
      <label>Date To:</label> <br />
      <mat-form-field
        [ngClass]="{
          invalid: SubletHeader.dateTo == null && error,
          '': !error
        }"
      >
        <input
          matInput
          [matDatepicker]="picker2"
          placeholder="Choose a date"
          [(ngModel)]="SubletHeader.dateTo"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-sm-6 col-md-6">
      <label>Supplier : </label> <br />
      <select
        name="ddlSupplier"
        [(ngModel)]="SubletHeader.supplier_id"
        class="longDropdownList"
        [ngClass]="{
          invalid: SubletHeader.supplier_id == 0 && error,
          '': !error
        }"
        (ngModelChange)="ddlSupplier_SelectedIndexChanged()"
      >
        <option *ngFor="let supplier of lstSupplier" [ngValue]="supplier.id">
          {{ supplier.name }}
        </option>
      </select>
    </div>
    <div class="col-sm-6 col-md-6">
      <label>Status : </label> <br />
      <select
        name="ddlStatus"
        [(ngModel)]="SubletHeader.status_id"
        [ngClass]="{
          invalid: SubletHeader.status_id == 0 && error,
          '': !error
        }"
      >
        <option
          *ngFor="let subletStatus of lstSubletStatus"
          [ngValue]="subletStatus.value"
        >
          {{ subletStatus.key }}
        </option>
      </select>
    </div>
  </div>
</fieldset>
<fieldset class="table">
  <legend>Sublet creator infos:</legend>
  <div class="row m-0">
    <div class="col-sm-6">
      <label>Created by: </label>
      <input
        type="text"
        class="extendedTextBox"
        [(ngModel)]="SubletHeader.createdBy.fullName"
        [disabled]="true"
      />
    </div>
    <div class="col-sm-6">
      <label>Date created: </label>
      <input
        type="text"
        class="normalTextBox"
        [(ngModel)]="SubletHeader.dateCreatedString"
        [disabled]="true"
      />
    </div>
  </div>
</fieldset>
<fieldset class="table">
  <legend class="m-0">Sublet Detail:</legend>
  <div class="row m-0">
    <div class="col-sm-12" *ngIf="message.length > 0">
      <label [ngClass]="{ 'text-danger': error, 'text-success': !error }">{{
        message
      }}</label>
    </div>
    <div class="col-sm-12 col-md-5_2 pr-0">
      <fieldset>
        <legend>Delivered To:</legend>
        <div class="row col-sm-12">
          <div class="col-sm-3 p-0"><span>Campus:</span></div>
          <div class="col-sm-9">
            <select
              name="ddlShipToCampus"
              [(ngModel)]="SubletHeader.shipToCampus_id"
              ng-init="SubletHeader.shipToCampus_id > 0 ? SubletHeader.shipToCampus_id : lstShipCampus[0].id"
              class="veryLongDropdownList"
              (ngModelChange)="ddlShipToCampus_SelectedIndexChanged()"
            >
              <option
                *ngFor="let campus of lstShipCampus"
                [ngValue]="campus.id"
              >
                {{ campus.name }}
              </option>
            </select>
          </div>
          <div class="col-sm-3 p-0"><span>Sector:</span></div>
          <div class="col-sm-9">
            <select
              name="ddlShipToSector"
              [(ngModel)]="SubletHeader.shipToSector_id"
              ng-init="SubletHeader.shipToSector_id > 0 ? SubletHeader.shipToSector_id : lstShipSector[0].id"
              class="veryLongDropdownList"
              (ngModelChange)="ddlShipToSector_SelectedIndexChanged()"
            >
              <option
                *ngFor="let sector of lstShipSector"
                [ngValue]="sector.id"
              >
                {{ sector.code }}
              </option>
            </select>
          </div>
          <div class="col-sm-3 p-0"><span>Building:</span></div>
          <div class="col-sm-9">
            <select
              name="ddlShipToBuilding"
              [(ngModel)]="SubletHeader.shipToBuilding_id"
              ng-init="SubletHeader.shipToBuilding_id > 0 ? SubletHeader.shipToBuilding_id : lstShipBuilding[0].id"
              class="veryLongDropdownList"
              (ngModelChange)="ddlShipToBuilding_SelectedIndexChanged()"
            >
              <option
                *ngFor="let building of lstShipBuilding"
                [ngValue]="building.id"
              >
                {{ building.name }}
              </option>
            </select>
          </div>
          <div class="col-sm-3 p-0"><span>Address:</span></div>
          <div class="col-sm-9">
            <span>{{ SubletHeader.shipToBuilding.address }}</span>
          </div>
          <div class="col-sm-3 p-0"><span>City:</span></div>
          <div class="col-sm-9">
            <span>{{ SubletHeader.shipToBuilding.city }}</span>
          </div>
          <div class="col-sm-3 p-0"><span>Province:</span></div>
          <div class="col-sm-9">
            <span>{{ SubletHeader.shipToBuilding.state }}</span>
          </div>
          <div class="col-sm-3 p-0"><span>Country:</span></div>
          <div class="col-sm-9">
            <span>{{ SubletHeader.shipToBuilding.country }}</span>
          </div>
          <div class="col-sm-3 p-0"><span>Postal Code:</span></div>
          <div class="col-sm-9">
            <span>{{ SubletHeader.shipToBuilding.zip }}</span>
          </div>
          <div class="col-sm-3 p-0"><span>Porter:</span></div>
          <div class="col-sm-9">
            <span>{{
              SubletHeader.shipToBuilding.defaultPorter.fullName
            }}</span>
          </div>
          <div class="col-sm-3 p-0"><span>Phone num:</span></div>
          <div class="col-sm-9">
            <span>{{ SubletHeader.shipToBuilding.phone }}</span>
          </div>
          <div class="col-sm-3 p-0"><span>Pager num:</span></div>
          <div class="col-sm-9">
            <span>{{ SubletHeader.shipToBuilding.pager }}</span>
          </div>
          <div class="col-sm-3 p-0"><span>Blgd code:</span></div>
          <div class="col-sm-9">
            <span>{{ SubletHeader.shipToBuilding.blgdCode }}</span>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="col-sm-12  offset-sm-0 col-md-5_2 offset-md-1 pl-0">
      <fieldset>
        <legend>Bill To:</legend>
        <div class="row col-sm-12">
          <div class="col-sm-3 p-0"><span>Campus:</span></div>
          <div class="col-sm-9">
            <select
              name="ddlBillToCampus"
              [(ngModel)]="SubletHeader.billToCampus_id"
              ng-init="SubletHeader.billToCampus_id > 0 ? SubletHeader.billToCampus_id : lstBillCampus[0].id"
              class="veryLongDropdownList"
              (ngModelChange)="ddlBillToCampus_SelectedIndexChanged()"
            >
              <option
                *ngFor="let campus of lstBillCampus"
                [ngValue]="campus.id"
              >
                {{ campus.name }}
              </option>
            </select>
          </div>
          <div class="col-sm-3 p-0"><span>Sector:</span></div>
          <div class="col-sm-9">
            <select
              name="ddlBillToSector"
              [(ngModel)]="SubletHeader.billToSector_id"
              ng-init="SubletHeader.billToSector_id > 0 ? SubletHeader.billToSector_id : lstBillSector[0].id"
              class="veryLongDropdownList"
              (ngModelChange)="ddlBillToSector_SelectedIndexChanged()"
            >
              <option
                *ngFor="let sector of lstBillSector"
                [ngValue]="sector.id"
              >
                {{ sector.code }}
              </option>
            </select>
          </div>
          <div class="col-sm-3 p-0"><span>Building:</span></div>
          <div class="col-sm-9">
            <select
              name="ddlBillToBuilding"
              [(ngModel)]="SubletHeader.billToBuilding_id"
              ng-init="SubletHeader.billToBuilding_id > 0 ? SubletHeader.billToBuilding_id : lstBillBuilding[0].id"
              class="veryLongDropdownList"
              (ngModelChange)="ddlBillToBuilding_SelectedIndexChanged()"
            >
              <option
                *ngFor="let building of lstBillBuilding"
                [ngValue]="building.id"
              >
                {{ building.name }}
              </option>
            </select>
          </div>
          <div class="col-sm-3 p-0"><span>Address:</span></div>
          <div class="col-sm-9">
            <span>{{ SubletHeader.billToBuilding.address }}</span>
          </div>
          <div class="col-sm-3 p-0"><span>City:</span></div>
          <div class="col-sm-9">
            <span>{{ SubletHeader.billToBuilding.city }}</span>
          </div>
          <div class="col-sm-3 p-0"><span>Province:</span></div>
          <div class="col-sm-9">
            <span>{{ SubletHeader.billToBuilding.state }}</span>
          </div>
          <div class="col-sm-3 p-0"><span>Country:</span></div>
          <div class="col-sm-9">
            <span>{{ SubletHeader.billToBuilding.country }}</span>
          </div>
          <div class="col-sm-3 p-0"><span>Postal Code:</span></div>
          <div class="col-sm-9">
            <span>{{ SubletHeader.billToBuilding.zip }}</span>
          </div>
          <div class="col-sm-3 p-0"><span>Porter:</span></div>
          <div class="col-sm-9">
            <span>{{
              SubletHeader.billToBuilding.defaultPorter.fullName
            }}</span>
          </div>
          <div class="col-sm-3 p-0"><span>Phone num:</span></div>
          <div class="col-sm-9">
            <span>{{ SubletHeader.billToBuilding.phone }}</span>
          </div>
          <div class="col-sm-3 p-0"><span>Pager num:</span></div>
          <div class="col-sm-9">
            <span>{{ SubletHeader.billToBuilding.pager }}</span>
          </div>
          <div class="col-sm-3 p-0"><span>Blgd code:</span></div>
          <div class="col-sm-9">
            <span>{{ SubletHeader.billToBuilding.blgdCode }}</span>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
  <div class="col-sm-12">
    <fieldset>
      <legend>Sublet Items</legend>
      <div class="row">
        <div class="col-sm-6 col-md-6">
          <label>Category : </label>
          <select
            name="ddlCategory"
            [(ngModel)]="selectedCategory"
            (ngModelChange)="BindSubCategory(selectedCategory)"
            class="longDropdownList"
          >
            <option *ngFor="let category of lstCategory" [ngValue]="category">
              {{ category.name }}
            </option>
          </select>
        </div>
        <div class="col-sm-6 col-md-6">
          <label>SubCategory : </label>
          <select
            name="ddlSubCategory"
            [(ngModel)]="selectedSubCategory"
            class="longDropdownList"
            (ngModelChange)="BindProduct(selectedSubCategory)"
          >
            <option
              *ngFor="let subCategory of lstSubCategory"
              [ngValue]="subCategory"
            >
              {{ subCategory.name }}
            </option>
          </select>
        </div>
        <div class="col-sm-12 col-md-12">
          <label>Product : </label> <br />
          <select
            name="ddlProduct"
            [(ngModel)]="selectedProduct"
            class="veryLongDropdownList"
          >
            <option *ngFor="let product of lstProduct" [ngValue]="product">
              {{ product.name }}
            </option>
          </select>
        </div>
        <div class="col-sm-6 col-md-4">
          <label>Qty : </label> <br />
          <input
            name="txtQty"
            type="number"
            [(ngModel)]="qtyProduct"
            min="1"
            class="normalTextBox"
          />
        </div>
        <div class="col-sm-4 offset-sm-8 text-right">
          <input
            type="button"
            (click)="AddProductToSublet()"
            *ngIf="
              qtyProduct > 0 &&
              selectedCategory != undefined &&
              selectedSubCategory != undefined &&
              selectedProduct != undefined
            "
            value="Add Product"
            class="normalButton"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <app-grid
            [models]="SubletHeader.subletDetailDTOs"
            [lstGridColumn]="[
              {
                index: 1,
                name: 'productName',
                header: 'Product Name',
                readonly: true
              },
              {
                index: 2,
                name: 'qtyRequested',
                header: 'Qty',
                readonly: false,
                mask: '0999'
              },
              {
                index: 3,
                name: 'locationPrice',
                header: 'Rental Price',
                readonly: true
              },
              {
                index: 4,
                name: 'locationUnit',
                header: 'Unit',
                readonly: true
              }
            ]"
            entity="LocationDetail"
            (editFunction)="modifyProductSublet($event)"
            (deleteFunction)="deleteProductSublet($event)"
          ></app-grid>
        </div>
        <div class="col-sm-12" *ngIf="SubletHeader.subletItems != null">
          <fieldset>
            <legend>Sublet Items</legend>
            <div
              *ngFor="let subletItem of SubletHeader.subletItems; let i = index"
            >
              <label class="col-sm-12 col-md-4 p-0">{{
                subletItem.productName
              }}</label>
              <select
                name="ddlSubletProduct"
                class="col-sm-12 col-md-6"
                [(ngModel)]="subletItem.product"
                (ngModelChange)="validateSameItem($event, i)"
              >
                <option
                  *ngFor="let prod of subletItem.lstProducts"
                  [ngValue]="prod"
                  >{{ prod.description }}</option
                >
              </select>
            </div>
          </fieldset>
        </div>
        <div class="col-sm-4 offset-sm-8 text-right">
          <input
            type="button"
            (click)="Save()"
            value="Save"
            *ngIf="!requestSent"
            class="normalButton"
          />
          <mat-spinner
            class="col-sm-4 offset-sm-10 SmallMat-spinner"
            *ngIf="requestSent"
          ></mat-spinner>
        </div>
      </div>
    </fieldset>
  </div>
</fieldset>
