<div class="row">
  <!-- <h2 class='col-sm-12'>
    <b>{{ title }}</b>
  </h2> -->
  <script src="https://cdnjs.cloudflare.com/ajax/libs/bluebird/3.3.5/bluebird.min.js"></script>
  <div class="col-sm-12 text-center">
    <mat-spinner class="mx-auto" *ngIf="loading"></mat-spinner>
    <iframe
      id="objectClassic"
      name="objectClassic"
      style="width:100%;margin-top:10px"
      [src]="classicUrl"
      (load)="disableSpinner()"
    ></iframe>
  </div>
</div>

<!-- <script>
  function disableSpinner() {
    const elem = document.getElementById('divLock');
    if (elem != null) {
      elem.parentNode.removeChild(elem);
    }
    resizeWindow();
  }

  function resizeWindow() {
    const height = window.innerHeight;
    const obj = document.getElementsByTagName('app-nav-menu')[0].children[0]
      .offsetHeight;
    const object = document.getElementById('objectClassic');
    object.style.height = height - obj - 70 + 'px';
  }
</script> -->
