import { Component, OnInit } from '@angular/core';
import { Product } from '../../../classes/Product';
import { ProductCategory } from '../../../classes/ProductCategory';
import { ProductSubCategory } from '../../../classes/ProductSubCategory';
import { ActivatedRoute } from '@angular/router';
import {
  SubletStatusEnum,
  values,
  ApiCallMethod
} from '../Helpers/McGillConstant';
import { InventoryItem } from '../../../classes/InventoryItem';
import { Supplier } from '../../../classes/Supplier';
import { SubletHeader } from '../../../classes/SubletHeader';
import { SubletDetail } from '../../../classes/SubletDetail';
import { Campus } from '../../../classes/Campus';
import { Sector } from '../../../classes/Sector';
import { Building } from '../../../classes/Building';
import { ApiService } from '../Helpers/ApiService';
import { ProductPricing } from '../../../classes/ProductPricing';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-subletDetail',
  templateUrl: './subletDetail.component.html'
})
export class SubletDetailComponent implements OnInit {
  public SubletHeader: SubletHeader;
  public selectedCategory: ProductCategory;
  public selectedSubCategory: ProductSubCategory;
  public selectedProduct: Product;
  public qtyProduct: number;
  public lstSupplier: Supplier[];
  public lstSubletStatus = values(SubletStatusEnum);
  public lstCategory: ProductCategory[];
  public lstSubCategory: ProductSubCategory[];
  public lstProduct: Product[];
  public lstShipCampus: Campus[];
  public lstShipSector: Sector[];
  public lstShipBuilding: Building[];
  public lstBillCampus: Campus[];
  public lstBillSector: Sector[];
  public lstBillBuilding: Building[];
  public model: any[] = [];
  public error: boolean;
  public message = '';
  public id: number;
  public requestSent = false;

  constructor(private route: ActivatedRoute, private api: ApiService) {
    this.qtyProduct = 1;
    this.SubletHeader = new SubletHeader();
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params.id;
      if (this.id != 0) {
        // call API
        this.api
          .CallWebApi(
            ApiCallMethod.Get,
            'SubletHeaders/' + this.id,
            true,
            '',
            ''
          )
          .then((result: SubletHeader) => {
            this.SubletHeader = result;
            this.BindCampus('Bill');
            this.BindCampus('Ship');
          });
      } else {
        this.SubletHeader = new SubletHeader();
        this.SubletHeader.dateCreated = new Date();
        this.SubletHeader.dateCreatedString = this.SubletHeader.dateCreated
          .toISOString()
          .split('T')[0];
        this.SubletHeader.dateFrom = new Date();
        this.SubletHeader.createdBy.fullName = localStorage.getItem('FullName');
        this.SubletHeader.createdBy_id = Number(localStorage.getItem('UserId'));
        this.BindCampus(null);
      }
    });
    //this.BindCategory();
    this.BindSupplier();
  }

  BindSupplier() {
    this.api
      .CallWebApi(ApiCallMethod.Get, 'Suppliers/?forRental=true', true, '', '')
      .then((result: Supplier[]) => {
        this.lstSupplier = result;
        if (this.SubletHeader.supplier_id == null) {
          this.SubletHeader.supplier_id = this.lstSupplier[0]
            ? this.lstSupplier[0].id
            : 0;
        }
        this.BindCategory(this.SubletHeader.supplier_id);
      });
  }

  BindCampus(dropDown) {
    this.api
      .CallWebApi(ApiCallMethod.Get, 'Campus/', true, '', '')
      .then((result: Campus[]) => {
        if (dropDown == null || dropDown == 'Bill') {
          this.lstBillCampus = result;
          if (
            this.SubletHeader.billToCampus_id == 0 ||
            this.lstBillCampus.filter(
              x => x.id == this.SubletHeader.billToCampus_id
            ).length == 0
          ) {
            this.SubletHeader.billToCampus_id = this.lstBillCampus[0]
              ? this.lstBillCampus[0].id
              : 0;
          }
          this.BindSector(this.SubletHeader.billToCampus_id, dropDown);
        }
        if (dropDown == null || dropDown == 'Ship') {
          this.lstShipCampus = result;
          if (
            this.SubletHeader.shipToCampus_id == 0 ||
            this.lstShipCampus.filter(
              x => x.id == this.SubletHeader.shipToCampus_id
            ).length == 0
          ) {
            this.SubletHeader.shipToCampus_id = this.lstShipCampus[0]
              ? this.lstShipCampus[0].id
              : 0;
          }
          if (dropDown != null) {
            this.BindSector(this.SubletHeader.shipToCampus_id, dropDown);
          }
        }
      });
  }

  BindSector(campus_id, dropDown) {
    this.api
      .CallWebApi(
        ApiCallMethod.Get,
        'Sectors/GetByCampusId/' + campus_id,
        true,
        '',
        ''
      )
      .then((result: Sector[]) => {
        if (dropDown == null || dropDown == 'Bill') {
          this.lstBillSector = result;
          if (
            this.SubletHeader.billToSector_id == 0 ||
            this.lstBillSector.filter(
              x => x.id == this.SubletHeader.billToSector_id
            ).length == 0
          ) {
            this.SubletHeader.billToSector_id = this.lstBillSector[0]
              ? this.lstBillSector[0].id
              : 0;
          }
          this.BindBuilding(this.SubletHeader.billToSector_id, dropDown);
        }
        if (dropDown == null || dropDown == 'Ship') {
          this.lstShipSector = result;
          if (
            this.SubletHeader.shipToSector_id == 0 ||
            this.lstShipSector.filter(
              x => x.id == this.SubletHeader.shipToSector_id
            ).length == 0
          ) {
            this.SubletHeader.shipToSector_id = this.lstShipSector[0]
              ? this.lstShipSector[0].id
              : 0;
          }
          if (dropDown != null) {
            this.BindBuilding(this.SubletHeader.shipToSector_id, dropDown);
          }
        }
      });
  }

  BindBuilding(sector_id, dropDown) {
    this.api
      .CallWebApi(
        ApiCallMethod.Get,
        'Buildings/GetBySectorId/' + sector_id,
        true,
        '',
        ''
      )
      .then((result: Building[]) => {
        if (dropDown == null || dropDown == 'Bill') {
          this.lstBillBuilding = result;
          if (
            this.SubletHeader.billToBuilding_id == 0 ||
            this.lstBillBuilding.filter(
              x => x.id == this.SubletHeader.billToBuilding_id
            ).length == 0
          ) {
            this.SubletHeader.billToBuilding_id = this.lstBillBuilding[0]
              ? this.lstBillBuilding[0].id
              : 0;
          }
          this.BindBuildingDetail(
            this.SubletHeader.billToBuilding_id,
            dropDown
          );
        }
        if (dropDown == null || dropDown == 'Ship') {
          this.lstShipBuilding = result;
          if (
            this.SubletHeader.shipToBuilding_id == 0 ||
            this.lstShipBuilding.filter(
              x => x.id == this.SubletHeader.shipToBuilding_id
            ).length == 0
          ) {
            this.SubletHeader.shipToBuilding_id = this.lstShipBuilding[0]
              ? this.lstShipBuilding[0].id
              : 0;
          }
          if (dropDown != null) {
            this.BindBuildingDetail(
              this.SubletHeader.shipToBuilding_id,
              dropDown
            );
          }
        }
      });
  }

  BindBuildingDetail(building_id, dropDown) {
    this.api
      .CallWebApi(ApiCallMethod.Get, 'Buildings/' + building_id, true, '', '')
      .then((building: Building) => {
        if (dropDown == null || dropDown == 'Bill') {
          this.SubletHeader.billToBuilding = building;
        }
        if (dropDown == null || dropDown == 'Ship') {
          this.SubletHeader.shipToBuilding = building;
        }
      });
  }

  BindCategory(supplier_id) {
    this.api
      .CallWebApi(
        ApiCallMethod.Get,
        /*'ProductCategories?forRental=true'*/ 'ProductCategories/GetBySupplierId/' +
          supplier_id,
        true,
        '',
        ''
      )
      .then((result: ProductCategory[]) => {
        this.lstCategory = result;
        if (this.lstCategory.length > 0) {
          this.selectedCategory = this.lstCategory[0];
          this.BindSubCategory(this.lstCategory[0]);
        }
      });
  }

  BindSubCategory(productCategory) {
    this.api
      .CallWebApi(
        ApiCallMethod.Get,
        'ProductSubCategories/GetByProductCategoryId/' +
          productCategory.id +
          '?forRental=true',
        true,
        '',
        ''
      )
      .then((result: ProductSubCategory[]) => {
        this.lstSubCategory = result;
        if (this.lstSubCategory.length > 0) {
          this.selectedSubCategory = this.lstSubCategory[0];
          this.BindProduct(this.lstSubCategory[0]);
        }
      });
  }

  BindProduct(productSubCategory) {
    this.api
      .CallWebApi(
        ApiCallMethod.Get,
        'Products/GetByProductSubCategoryId/' +
          productSubCategory.id +
          '?supplierId=' +
          this.SubletHeader.supplier_id +
          '&&forRental=true',
        true,
        '',
        ''
      )
      .then((result: Product[]) => {
        this.lstProduct = result;
        this.selectedProduct = this.lstProduct[0];
      });
  }

  AddProductToSublet() {
    this.error = false;
    this.message = '';
    if (
      this.SubletHeader.subletDetailDTOs != null &&
      this.SubletHeader.subletDetailDTOs
        .map(x => x.productName)
        .includes(this.selectedProduct.name)
    ) {
      this.error = true;
      this.message = 'Cannot add same product to the event !';
    } else {
      // Get locationPrice and unit
      this.api
        .CallWebApi(
          ApiCallMethod.Get,
          'ProductPricings/GetLastByProductSupplier/' +
            this.selectedProduct.id +
            '/' +
            this.SubletHeader.supplier_id,
          true,
          '',
          ''
        )
        .then((result: ProductPricing) => {
          this.selectedProduct.productPricing = result;
          const sublet = new SubletDetail(
            0,
            new Date(),
            Number(localStorage.getItem('UserId')),
            this.selectedProduct,
            this.qtyProduct,
            0,
            this.id
          );
          sublet['CanEdit'] = true;
          if (this.SubletHeader.subletDetailDTOs == null) {
            this.SubletHeader.subletDetailDTOs = [sublet];
          } else {
            this.SubletHeader.subletDetailDTOs.push(sublet);
          }

          this.SubletHeader.subletDetailDTOs = JSON.parse(
            JSON.stringify(this.SubletHeader.subletDetailDTOs)
          );
        });
    }
  }

  modifyProductSublet(index) {
    const sublet = this.SubletHeader.subletDetailDTOs[index];
    if (sublet != null) {
      this.api
        .CallWebApi(
          ApiCallMethod.Get,
          'InventoryItems/GetByProductId/' +
            sublet.product_id +
            '?includeInactive=true',
          true,
          '',
          ''
        )
        .then((result: InventoryItem[]) => {});
    }
  }

  deleteProductSublet(index) {
    const productId = this.SubletHeader.subletDetailDTOs[index].product_id;

    for (let i = this.SubletHeader.subletItems.length - 1; i >= 0; i--) {
      if (this.SubletHeader.subletItems[i].product_id == productId) {
        this.SubletHeader.subletItems.splice(i, 1);
      }
    }

    if (this.SubletHeader.subletItems.length == 0) {
      this.SubletHeader.subletItems = null;
    }

    for (let i = this.SubletHeader.subletItems.length - 1; i >= 0; i--) {
      if (this.SubletHeader.subletItems[i].product_id == productId) {
        this.SubletHeader.subletItems.splice(i, 1);
      }
    }

    if (this.SubletHeader.subletItems.length == 0) {
      this.SubletHeader.subletItems = null;
    }
  }

  ddlShipToCampus_SelectedIndexChanged() {
    this.BindSector(this.SubletHeader.shipToCampus_id, 'Ship');
  }

  ddlShipToSector_SelectedIndexChanged() {
    this.BindBuilding(this.SubletHeader.shipToSector_id, 'Ship');
  }

  ddlShipToBuilding_SelectedIndexChanged() {
    this.BindBuildingDetail(this.SubletHeader.shipToBuilding_id, 'Ship');
  }

  ddlBillToCampus_SelectedIndexChanged() {
    this.BindSector(this.SubletHeader.billToCampus_id, 'Bill');
  }

  ddlBillToSector_SelectedIndexChanged() {
    this.BindBuilding(this.SubletHeader.billToSector_id, 'Bill');
  }

  ddlBillToBuilding_SelectedIndexChanged() {
    this.BindBuildingDetail(this.SubletHeader.billToBuilding_id, 'Bill');
  }

  ddlSupplier_SelectedIndexChanged() {
    this.BindCategory(this.SubletHeader.supplier_id);
  }

  Save() {
    this.requestSent = true;
    this.message = '';
    if (this.id > 0) {
      this.api
        .CallWebApi(
          ApiCallMethod.Put,
          'SubletHeaders/' + this.id,
          true,
          this.SubletHeader,
          ''
        )
        .then((result: SubletHeader) => {
          this.SubletHeader = result;
          this.message = 'SubletHeader saved successfully !';
          this.error = false;
          this.requestSent = false;
        })
        .catch(res => {
          this.message = 'SubletHeader has not been saved !';
          this.error = true;
          this.requestSent = false;
        });
    } else {
      this.api
        .CallWebApi(
          ApiCallMethod.Post,
          'SubletHeaders/',
          true,
          this.SubletHeader,
          ''
        )
        .then((result: SubletHeader) => {
          this.SubletHeader = result;
          this.api
            .CallWebApi(
              ApiCallMethod.Post,
              'Orders/CreateOrderFromSublet',
              true,
              this.SubletHeader,
              ''
            )
            .then(result => {
              this.message = 'SubletHeader saved successfully !';
              this.error = false;
              this.requestSent = false;
            })
            .catch(res => {
              this.message = 'SubletHeader has not been saved !';
              this.error = true;
              this.requestSent = false;
            });
        })
        .catch(res => {
          this.message = 'SubletHeader has not been saved !';
          this.error = true;
          this.requestSent = false;
        });
    }
  }

  validateSameItem(event, index: number) {
    if (
      this.SubletHeader.subletItems
        .map(x => x.product)
        .filter(x => x != undefined && x.id == event.id).length > 1
    ) {
      this.SubletHeader.subletItems[index].product = new Product();
      event.inventoryItem = -1;
      alert('Cannot select 2 times the same inventory item !');
    }
  }
}
