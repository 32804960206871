/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../gridComponent/grid.component.ngfactory";
import * as i2 from "../gridComponent/grid.component";
import * as i3 from "../Helpers/ApiService";
import * as i4 from "./subletReception.component";
var styles_SubletReceptionComponent = [];
var RenderType_SubletReceptionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SubletReceptionComponent, data: {} });
export { RenderType_SubletReceptionComponent as RenderType_SubletReceptionComponent };
export function View_SubletReceptionComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Sublet List"])), (_l()(), i0.ɵeld(2, 0, null, null, 9, "fieldset", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "legend", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Sublets :"])), (_l()(), i0.ɵeld(5, 0, null, null, 6, "app-grid", [["editLink", "true"], ["entity", "SubletHeader"]], null, null, null, i1.View_GridComponent_0, i1.RenderType_GridComponent)), i0.ɵdid(6, 573440, null, 0, i2.GridComponent, [i3.ApiService], { models: [0, "models"], lstGridColumn: [1, "lstGridColumn"], editLink: [2, "editLink"], entity: [3, "entity"] }, null), i0.ɵpod(7, { index: 0, name: 1, header: 2, readonly: 3 }), i0.ɵpod(8, { index: 0, name: 1, header: 2, readonly: 3 }), i0.ɵpod(9, { index: 0, name: 1, header: 2, readonly: 3 }), i0.ɵpod(10, { index: 0, name: 1, header: 2, readonly: 3 }), i0.ɵpad(11, 4)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; var currVal_1 = _ck(_v, 11, 0, _ck(_v, 7, 0, 1, "id", "Id", true), _ck(_v, 8, 0, 2, "supplierName", "Supplier", true), _ck(_v, 9, 0, 3, "dateFromString", "Date From", true), _ck(_v, 10, 0, 4, "dateToString", "Date To", true)); var currVal_2 = "true"; var currVal_3 = "SubletHeader"; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_SubletReceptionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-subletReception", [], null, null, null, View_SubletReceptionComponent_0, RenderType_SubletReceptionComponent)), i0.ɵdid(1, 114688, null, 0, i4.SubletReceptionComponent, [i3.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SubletReceptionComponentNgFactory = i0.ɵccf("app-subletReception", i4.SubletReceptionComponent, View_SubletReceptionComponent_Host_0, {}, {}, []);
export { SubletReceptionComponentNgFactory as SubletReceptionComponentNgFactory };
