import { User } from './User';

export class Building {
  id: number;
  isActive: boolean;
  dateCreated: Date;
  name: string;
  buildingCode: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  squareFootage: number;
  phone: string;
  pager: string;
  blgdCode: string;
  defaultPorter_id: number;
  defaultPorter: User;

  constructor() {
    this.id = 0;
    this.isActive = true;
    this.dateCreated = new Date();
    this.name = '';
    this.buildingCode = '';
    this.address = '';
    this.city = '';
    this.state = '';
    this.country = '';
    this.zip = '';
    this.squareFootage = 0;
    this.phone = '';
    this.pager = '';
    this.blgdCode = '';
    this.defaultPorter_id = 0;
    this.defaultPorter = new User();
    this.defaultPorter.fullName = '';
  }
}
