import { Department } from './Department';

export class ClientArea {
  id: number;
  client_id: number;
  department: Department;
  department_id: number;

  constructor(clientId, departmentId) {
    this.id = 0;
    this.client_id = clientId;
    this.department_id = departmentId;
    this.department = null;
  }
}
