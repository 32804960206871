<h3>Rental List</h3>
<!--
  <fieldset>

    <legend>Filters :</legend>


  </fieldset>
-->
<fieldset>
  <legend>Rentals :</legend>
  <app-grid
    [models]="model"
    [lstGridColumn]="[
      { index: 1, name: 'id', header: 'Id', readonly: true },
      { index: 2, name: 'clientName', header: 'Client', readonly: true },
      { index: 3, name: 'name', header: 'Event Name', readonly: true },
      { index: 4, name: 'dateFromString', header: 'Date From', readonly: true },
      { index: 5, name: 'dateToString', header: 'Date To', readonly: true }
    ]"
    entity="LocationHeader"
    editLink="true"
  ></app-grid>
</fieldset>
