import { Component, OnInit } from "@angular/core";
import { SubletHeader } from "../../../classes/SubletHeader";
import { ApiService } from "../Helpers/ApiService";
import { ApiCallMethod } from "../Helpers/McGillConstant";

@Component({
  selector: "app-sublet",
  templateUrl: "./sublet.component.html"
})
export class SubletComponent implements OnInit {
  public model: SubletHeader[];

  constructor(private api: ApiService) {}

  ngOnInit(): void {
    this.api
      .CallWebApi(ApiCallMethod.Get, "SubletHeaders/", true, "", "")
      .then((result: SubletHeader[]) => {
        this.model = result;
      });
  }
}
