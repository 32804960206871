import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  Output,
  EventEmitter
} from '@angular/core';
import { GridColumn } from '../../../classes/GridColumn';
import { GridObject } from '../../../classes/GridObject';
import { ApiCallMethod } from '../Helpers/McGillConstant';
import { ApiService } from '../Helpers/ApiService';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html'
})
export class GridComponent implements OnChanges /*, OnInit*/ {
  @Input()
  models: any[];
  @Input()
  lstGridColumn: GridColumn[];
  @Input()
  editLink: boolean;
  @Input()
  entity: string;
  @Output() editFunction: EventEmitter<any> = new EventEmitter();
  @Output() deleteFunction: EventEmitter<any> = new EventEmitter();
  @Output() validationFunction: EventEmitter<any> = new EventEmitter();
  public oldModels: any[];
  public lstColumn: GridObject[] = [];
  public sort: string;
  public reverse: boolean;
  public message = '';
  public success: boolean;
  private canEdit = false;

  constructor(private api: ApiService) {}

  // ngOnInit(): void {
  //   debugger;
  //   if (this.models.length > 0) {
  //     this.oldModels = JSON.parse(JSON.stringify(this.models));
  //     let columnName = this.lstGridColumn.map(x => x.name);

  //     let result = Object.keys(this.models[0]).filter(x =>
  //       columnName.includes(x)
  //     );
  //     result.forEach(e => {
  //       let canEdit = false;
  //       let gridColumn = this.lstGridColumn.filter(x => x.name == e)[0];
  //       canEdit =
  //         gridColumn.readonly == undefined ? false : !gridColumn.readonly;
  //       this.lstColumn.push({
  //         canEdit: canEdit,
  //         value: e
  //       });
  //     });
  //   }
  // }

  ngOnChanges(changes: SimpleChanges) {
    const name: SimpleChange = changes.models;
    if (
      name.previousValue != name.currentValue &&
      this.oldModels == undefined
    ) {
      if (this.models.length > 0) {
        const columnName = this.lstGridColumn.map(x => x.name);
        let modelIsEditable = false;
        columnName.forEach(e => {
          // const canEdit = false;
          const gridColumn = this.lstGridColumn.filter(x => x.name == e)[0];
          this.canEdit =
            gridColumn.readonly == undefined ? false : !gridColumn.readonly;
          if (!modelIsEditable && this.canEdit) {
            modelIsEditable = true;
            this.models.forEach(i => {
              i['CanEdit'] = true;
            });
          }
          this.lstColumn.push({
            canEdit: this.canEdit,
            value: e,
            mask: gridColumn.mask == null ? '' : gridColumn.mask,
            type: gridColumn.type == null ? 'text' : gridColumn.type,
            width: gridColumn.width
          });
        });
        this.oldModels = JSON.parse(JSON.stringify(this.models));
      }
    } else {
      // insert canEdit in model
      if (this.canEdit && this.models.filter(x => !x.canEdit).length > 0) {
        this.models
          .filter(x => !x.canEdit)
          .forEach(i => {
            i['CanEdit'] = true;
          });
        this.oldModels = JSON.parse(JSON.stringify(this.models));
      }
    }
  }

  edit(index) {
    this.models[index].EditMode =
      this.models[index].EditMode == undefined
        ? true
        : !this.models[index].EditMode;
  }

  acceptEdit(index) {
    if (this.validationFunction != null) {
      this.validationFunction.emit(index);
    } else {
      this.saveEdit(index);
    }
  }

  saveEdit(index) {
    if (this.models[index].id != undefined && this.models[index].id > 0) {
      this.models[index]['lastUpdatedBy_id'] = localStorage.getItem('UserId');
      this.api
        .CallWebApi(
          ApiCallMethod.Put,
          this.entity + 's/' + this.models[index].id,
          true,
          this.models[index],
          ''
        )
        .then((result: any) => {
          this.edit(index);
          this.oldModels[index] = this.models[index];
          if (this.editFunction != null) {
            this.editFunction.emit(index);
          }
          this.success = true;
          this.message = '';
        })
        .catch(res => {
          this.success = false;
          this.message = 'An error occured !';
        });
    } else {
      this.edit(index);
      if (this.editFunction != null) {
        this.editFunction.emit(index);
      }
      this.success = true;
      this.message = '';
    }
  }

  cancelEdit(index) {
    this.edit(index);
    this.models[index] = JSON.parse(JSON.stringify(this.oldModels[index]));
  }

  delete(index) {
    if (this.models[index].id != undefined && this.models[index].id > 0) {
      this.api
        .CallWebApi(
          ApiCallMethod.Delete,
          this.entity + 's/' + this.models[index].id,
          true,
          '',
          ''
        )
        .then((result: any) => {
          if (this.deleteFunction != null) {
            this.deleteFunction.emit(index);
          }
          this.models.splice(index, 1);
          this.oldModels.splice(index, 1);
          this.success = true;
          this.message = '';
        })
        .catch(res => {
          this.success = false;
          this.message = 'An error occured !';
        });
    } else {
      if (this.deleteFunction != null) {
        this.deleteFunction.emit(index);
      }
      this.models.splice(index, 1);
      this.oldModels.splice(index, 1);
      this.success = true;
      this.message = '';
    }
  }

  setSort(identifier) {
    this.models = this.models.sort(this.dynamicSort(identifier));
    this.sort = identifier;
  }

  dynamicSort(property) {
    let sortOrder = 1;
    if (!this.reverse && this.sort == property) {
      sortOrder = -1;
      this.reverse = true;
    } else {
      this.reverse = false;
    }
    return function(a, b) {
      const result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
}
