import { User } from './User';
import { SubletItem } from './SubletItem';
import { SubletDetail } from './SubletDetail';
import { Building } from './Building';
import { Supplier } from './Supplier';

export class SubletHeader {
  id: number;
  isActive: boolean;
  supplier_id: number;
  supplierName: string;
  supplier: Supplier;
  dateFrom: Date;
  dateFromString: string;
  dateTo: Date;
  dateToString: string;
  createdBy: User;
  createdBy_id: number;
  dateCreated: Date;
  dateCreatedString: string;
  lastUpdatedBy: User;
  lastUpdatedBy_id: number;
  lastUpdatedDate: Date;
  status_id: number;
  shipToCampus_id: number;
  shipToSector_id: number;
  shipToBuilding_id: number;
  shipToBuilding: Building;
  billToCampus_id: number;
  billToSector_id: number;
  billToBuilding_id: number;
  billToBuilding: Building;
  subletItems: SubletItem[];
  subletDetailDTOs: SubletDetail[];

  constructor() {
    this.id = 0;
    this.isActive = true;
    this.supplier_id = null;
    this.supplier = null;
    this.dateFrom = null;
    this.dateTo = null;
    this.createdBy_id = 0;
    this.createdBy = new User();
    this.dateCreated = new Date();
    this.lastUpdatedBy = null;
    this.lastUpdatedBy_id = null;
    this.lastUpdatedDate = null;
    this.status_id = 1;
    this.billToBuilding = new Building();
    this.shipToBuilding = new Building();
  }
}
