<form
  name="login"
  (ngSubmit)="f.form.valid && onSubmit()"
  #f="ngForm"
  novalidate
>
  <div
    class="col-sm-4 col-sm-offset-4 loginbox"
    style="background-color: white;"
  >
    <div class="row">
      <div class="col-sm-5 p-2">
        <img class="img-fluid" src="assets/Images/logo.png" alt="Logo" />
      </div>
      <div class="col-sm-7 p-2">
        <div class="row">
          <span class="logoHeader">U.S.I.D.S.</span>
        </div>
        <div class="row" *ngIf="showMessage">
          <label
            ID="lblMessage"
            class="text-danger"
            style="text-align:center"
            >{{ lblMessage }}</label
          >
        </div>
        <div class="row">
          <div class="col-sm-4 p-0">
            <label>Username:</label>
          </div>
          <div class="col-sm-8 float-right">
            <input
              id="txtUsername"
              name="username"
              [(ngModel)]="model.Username"
              #username="ngModel"
              [ngClass]="{ 'is-invalid': f.submitted && username.invalid }"
              type="text"
              class="normalTextBox form-control"
              required
            />
            <div
              *ngIf="f.submitted && username.invalid"
              class="invalid-feedback"
            >
              <div *ngIf="username.errors.required">Username is required</div>
            </div>
          </div>
          <div class="col-sm-4 p-0">
            <label>Password:</label>
          </div>
          <div class="col-sm-8 float-right">
            <input
              id="txtPassword"
              name="password"
              [(ngModel)]="model.Password"
              #password="ngModel"
              [ngClass]="{ 'is-invalid': f.submitted && password.invalid }"
              type="password"
              class="normalTextBox
            form-control"
              required
            />
            <div
              *ngIf="f.submitted && password.invalid"
              class="invalid-feedback"
            >
              <div *ngIf="password.errors.required">Password is required</div>
            </div>
          </div>
          <div class="col-sm-12">
            <button
              id="butLogin"
              type="submit"
              [ngClass]="{ 'd-none': requestSent, '': !requestSent }"
              class="button float-right"
            >
              Login
            </button>
            <mat-spinner *ngIf="requestSent"></mat-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
