import { Product } from './Product';
import { Supplier } from './Supplier';

export class SubletItem {
  id: number;
  isActive: boolean;
  product: Product;
  product_id: number;
  productName: string;
  supplier_id: number;
  inventoryTag: string;
  lstSuppliers: Supplier[];
  lstProducts: Product[];

  constructor() {
    this.id = 0;
    this.isActive = true;
    this.product = new Product();
    this.product_id = 0;
    this.supplier_id = 0;
    this.productName = '';
    this.inventoryTag = '';
  }
}
