import { Enum } from '../../../classes/Enum';

export enum ApiCallMethod {
  Get = 1,
  Post = 2,
  Put = 3,
  Delete = 4
}

export enum RentalStatusEnum {
  'In Progress' = 1,
  'Completed' = 2
}

export enum SubletStatusEnum {
  'Waiting For Approval' = 1,
  'Approved' = 2,
  'Declined' = 3,
  'In Progress' = 4,
  'Completed' = 5
}

export enum ClientTypeEnum {
  Internal = 1,
  External = 2
}

export enum PaymentModeEnum {
  Cash = 1,
  Cheque = 2,
  'Bank Transfer' = 3,
  'Credit Card' = 4,
  'External Client Account' = 5,
  FOAPAL = 6
}

export enum LocationUnitEnum {
  Hour = 1,
  Day = 2,
  Week = 3,
  Month = 4,
  Year = 5
}

export function values(EnumClass) {
  const object: Enum[] = [];
  const result1 = Object.keys(EnumClass).filter(
    type => isNaN(<any>type) && type != 'values'
  );
  const result2 = Object.keys(EnumClass).filter(type => !isNaN(<any>type));

  result1.forEach(item => {
    object.push(new Enum(item, result2[result1.indexOf(item)]));
  });

  return object;
}
