/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./nav-menu/nav-menu.component.ngfactory";
import * as i3 from "./nav-menu/nav-menu.component";
import * as i4 from "./Helpers/AuthService";
import * as i5 from "@angular/common";
import * as i6 from "@angular/router";
import * as i7 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-nav-menu", [], null, null, null, i2.View_NavMenuComponent_0, i2.RenderType_NavMenuComponent)), i1.ɵdid(1, 114688, null, 0, i3.NavMenuComponent, [i4.AuthService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "col-sm-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2 body-content"], ["style", "margin-top: 10px"]], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "col-md-12 body-content": 0, "col-sm-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2  body-content": 1 }), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = "col-sm-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2 body-content"; var currVal_1 = _ck(_v, 6, 0, _co.router.url.includes("classic/"), !_co.router.url.includes("classic/")); _ck(_v, 5, 0, currVal_0, currVal_1); _ck(_v, 8, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i7.AppComponent, [i6.Router], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i7.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
