import { User } from './User';
import { RentalReturn } from './RentalReturn';
import { Product } from './Product';
import { InventoryItem } from './InventoryItem';

export class RentalReturnProduct {
  id: number;
  createdBy: User;
  createdBy_id: number;
  dateCreated: Date;
  dateCreatedString: string;
  lastUpdatedBy: User;
  lastUpdatedBy_id: number;
  lastUpdateDate: Date;
  isActive: boolean;
  rentalReturn_id: number;
  rentalReturn: RentalReturn;
  product_id: number;
  product: Product;
  inventoryItem_id: number;
  inventoryItem: InventoryItem;
  isReturned: boolean;
  productName: string;

  constructor() {
    this.id = 0;
    this.createdBy_id = 0;
    this.createdBy = new User();
    this.dateCreated = new Date();
    this.lastUpdatedBy_id = null;
    this.lastUpdateDate = null;
    this.isActive = true;
    this.rentalReturn_id = 0;
    this.rentalReturn = null;
    this.isReturned = false;
    this.productName = '';
  }

  public assignFieldsFromLocationItem(locationItem) {
    this.product_id = locationItem.product_id;
    this.product = locationItem.product;
    this.inventoryItem_id = locationItem.inventoryItem_id;
    this.inventoryItem = locationItem.inventoryItem;
    this.productName = locationItem.productName;
  }
}
