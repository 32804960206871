<h3 *ngIf="LocationHeader.id == 0">Place new rental</h3>
<h3 *ngIf="LocationHeader.id > 0">View rental {{ LocationHeader.id }}</h3>
<fieldset class="table">
  <legend>Location Header:</legend>
  <div class="row m-0">
    <div class="col-sm-6 col-md-4">
      <label
        >Event Number : <b>{{ LocationHeader.id }}</b></label
      >
    </div>
  </div>
  <div class="row m-0">
    <div class="col-sm-6 col-md-4">
      <label>Date From:</label> <br />
      <mat-form-field
        [ngClass]="{
          invalid: LocationHeader.dateFrom == null && error,
          '': !error
        }"
      >
        <input
          matInput
          [matDatepicker]="picker"
          placeholder="Choose a date"
          [(ngModel)]="LocationHeader.dateFrom"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-sm-6 col-md-4">
      <label>Date To:</label> <br />
      <mat-form-field
        [ngClass]="{
          invalid: LocationHeader.dateTo == null && error,
          '': !error
        }"
      >
        <input
          matInput
          [matDatepicker]="picker2"
          placeholder="Choose a date"
          [(ngModel)]="LocationHeader.dateTo"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-sm-6 col-md-4">
      <label>Event Name : </label> <br />
      <input
        type="text"
        [(ngModel)]="LocationHeader.name"
        class="longTextBox"
        [ngClass]="{
          invalid: LocationHeader.name.length == 0 && error,
          '': !error
        }"
      />
    </div>
    <div class="col-sm-6 col-md-4">
      <label>Client : </label> <br />
      <select
        name="ddlClient"
        [(ngModel)]="LocationHeader.client_id"
        (ngModelChange)="ddlClient_SelectedIndexChanged()"
        class="longDropdownList"
        [ngClass]="{
          invalid: LocationHeader.client_id == 0 && error,
          '': !error
        }"
      >
        <option *ngFor="let client of lstClient" [ngValue]="client.id">
          {{ client.name }}
        </option>
      </select>
    </div>
    <div class="col-sm-6 col-md-4">
      <label>Status : </label> <br />
      <select
        name="ddlStatus"
        [(ngModel)]="LocationHeader.status_id"
        class="normalDropdownList"
        [ngClass]="{
          invalid: LocationHeader.status_id == 0 && error,
          '': !error
        }"
      >
        <option
          *ngFor="let rentalStatus of lstRentalStatus"
          [ngValue]="rentalStatus.value"
        >
          {{ rentalStatus.key }}
        </option>
      </select>
    </div>

    <div
      [ngClass]="{
        'col-sm-6 col-md-4':
          LocationHeader.paymentMode_id == paymentModeEnum['FOAPAL'],
        'col-sm-12 col-md-12':
          LocationHeader.paymentMode_id != paymentModeEnum['FOAPAL']
      }"
    >
      <label>Payment mode : </label> <br />
      <select
        name="ddlPaymentMode"
        [(ngModel)]="LocationHeader.paymentMode_id"
        ng-init="LocationHeader.paymentMode_id > 0 ? LocationHeader.paymentMode_id : lstPaymentMode[0]"
        class="normalDropdownList"
        [ngClass]="{
          invalid: LocationHeader.paymentMode_id == 0 && error,
          '': !error
        }"
      >
        <option
          *ngFor="let paymentMode of lstPaymentMode"
          [ngValue]="paymentMode.value"
        >
          {{ paymentMode.key }}
        </option>
      </select>
    </div>

    <div
      class="col-sm-6 col-md-8"
      *ngIf="LocationHeader.paymentMode_id == paymentModeEnum['FOAPAL']"
    >
      <fieldset>
        <legend>Foapal</legend>
        <label>Foapal :</label>
        <select
          name="ddlSelectedFoapal"
          [(ngModel)]="LocationHeader.clientFoapal_id"
        >
          <option *ngFor="let foapal of lstFoapal" [ngValue]="foapal.id">
            {{ foapal.number }}</option
          >
        </select>
      </fieldset>
    </div>

    <div class="col-sm-6 col-md-4">
      <label>Campus : </label> <br />
      <select
        name="ddlCampus"
        [(ngModel)]="LocationHeader.campus_id"
        ng-init="LocationHeader.campus_id > 0 ? LocationHeader.campus_id : lstCampus[0].id"
        class="normalDropdownList"
        (ngModelChange)="ddlCampus_SelectedIndexChanged()"
      >
        <option *ngFor="let campus of lstCampus" [ngValue]="campus.id">
          {{ campus.name }}
        </option>
      </select>
    </div>

    <div class="col-sm-6 col-md-4">
      <label>Sector : </label> <br />
      <select
        name="ddlSector"
        [(ngModel)]="LocationHeader.sector_id"
        ng-init="LocationHeader.sector_id > 0 ? LocationHeader.sector_id : lstSector[0].id"
        class="normalDropdownList"
        (ngModelChange)="ddlSector_SelectedIndexChanged()"
      >
        <option *ngFor="let sector of lstSector" [ngValue]="sector.id">
          {{ sector.code }}
        </option>
      </select>
    </div>

    <div class="col-sm-6 col-md-4">
      <label>Building : </label> <br />
      <select
        name="ddlBuilding"
        [(ngModel)]="LocationHeader.building_id"
        ng-init="LocationHeader.building_id > 0 ? LocationHeader.building_id : lstBuilding[0].id"
        (ngModelChange)="ddlBuilding_SelectedIndexChanged()"
      >
        <option *ngFor="let building of lstBuilding" [ngValue]="building.id">
          {{ building.name }}
        </option>
      </select>
    </div>

    <div class="col-sm-6 col-md-4">
      <label>Wing : </label> <br />
      <select
        name="ddlWing"
        [(ngModel)]="LocationHeader.wing"
        ng-init="LocationHeader.wing != '' ? LocationHeader.wing : lstWing[0]"
        class="normalDropdownList"
        (ngModelChange)="ddlWing_SelectedIndexChanged()"
      >
        <option *ngFor="let wing of lstWing" [ngValue]="wing">
          {{ wing }}
        </option>
      </select>
    </div>

    <div class="col-sm-6 col-md-4">
      <label>Floor : </label> <br />
      <select
        name="ddlFloor"
        [(ngModel)]="LocationHeader.floor_id"
        ng-init="LocationHeader.floor_id > 0 ? LocationHeader.floor_id : lstFloor[0].id"
        class="normalDropdownList"
        (ngModelChange)="ddlFloor_SelectedIndexChanged()"
      >
        <option *ngFor="let floor of lstFloor" [ngValue]="floor.id">
          {{ floor.name }}
        </option>
      </select>
    </div>

    <div class="col-sm-6 col-md-4">
      <label>Room : </label> <br />
      <select
        name="ddlRoom"
        [(ngModel)]="LocationHeader.room_id"
        ng-init="LocationHeader.room_id != '' ? LocationHeader.room_id : lstRoom[0].id"
        class="normalDropdownList"
        (ngModelChange)="ddlRoom_SelectedIndexChanged()"
      >
        <option *ngFor="let room of lstRoom" [ngValue]="room.id">
          {{ room.name }}
        </option>
      </select>
    </div>

    <div class="col-sm-6 col-md-4">
      <label>Room Use Description : </label> <br />
      <input
        type="text"
        disabled="disabled"
        [(ngModel)]="LocationHeader.roomUseDescription"
      />
    </div>

    <div class="col-sm-6 col-md-4">
      <label>Room Area : </label> <br />
      <input
        type="text"
        disabled="disabled"
        [(ngModel)]="LocationHeader.roomArea"
        class="normalTextBox"
      />
    </div>

    <div class="col-sm-6 col-md-4">
      <label>Seating : </label> <br />
      <input
        type="text"
        disabled="disabled"
        [(ngModel)]="LocationHeader.seating"
        class="normalTextBox"
      />
    </div>
  </div>
</fieldset>
<fieldset class="table">
  <legend>Location creator infos:</legend>
  <div class="row m-0">
    <div class="col-sm-6 col-md-4">
      <label>Created by: </label>
      <input
        type="text"
        class="extendedTextBox"
        [(ngModel)]="LocationHeader.createdBy.fullName"
        [disabled]="true"
      />
    </div>
    <div class="col-sm-6 col-md-4">
      <label>Date created: </label>
      <input
        type="text"
        class="normalTextBox"
        [(ngModel)]="LocationHeader.dateCreatedString"
        [disabled]="true"
      />
    </div>
  </div>
</fieldset>
<fieldset class="table">
  <legend>Location Detail:</legend>
  <div class="row m-0">
    <div class="col-sm-12" *ngIf="message.length > 0">
      <label [ngClass]="{ 'text-danger': error, 'text-success': !error }">{{
        message
      }}</label>
    </div>
    <div class="col-sm-6 col-md-6">
      <label>Category : </label><br />
      <select
        name="ddlCategory"
        [(ngModel)]="selectedCategory"
        (ngModelChange)="BindSubCategory(selectedCategory)"
        class="longDropdownList"
      >
        <option *ngFor="let category of lstCategory" [ngValue]="category">
          {{ category.name }}
        </option>
      </select>
    </div>
    <div class="col-sm-6 col-md-6">
      <label>SubCategory : </label><br />
      <select
        name="ddlSubCategory"
        [(ngModel)]="selectedSubCategory"
        class="longDropdownList"
        (ngModelChange)="BindProduct(selectedSubCategory)"
      >
        <option
          *ngFor="let subCategory of lstSubCategory"
          [ngValue]="subCategory"
        >
          {{ subCategory.name }}
        </option>
      </select>
    </div>
    <div class="col-sm-12 col-md-12">
      <label>Product : </label> <br />
      <select
        name="ddlProduct"
        [(ngModel)]="selectedProduct"
        class="veryLongDropdownList"
        (ngModelChange)="ddlProduct_SelectedIndexChanged()"
      >
        <option *ngFor="let product of lstProduct" [ngValue]="product">
          {{ product.name }}
        </option>
      </select>
    </div>
    <div
      class="col-sm-12 col-md-6 row"
      style="min-width: 250px;max-width: 250px"
    >
      <div class="col-sm-6 pr-0" style="margin-top:.5rem">
        <label>Inv Qty : </label><br />
        <input
          name="txtInvQty"
          type="text"
          [(ngModel)]="qtyAvailableInv"
          class="smallTextBox"
          disabled="disabled"
        />
      </div>
      <div class="col-sm-6 pr-0" style="margin-top:.5rem">
        <label>Sublet Inv Qty : </label><br />
        <input
          name="txtSubletInvQty"
          type="text"
          [(ngModel)]="qtyAvailableSublet"
          class="smallTextBox"
          disabled="disabled"
        />
      </div>
      <div class="col-sm-6 pr-0">
        <label>Qty : </label><br />
        <input
          name="txtQty"
          type="number"
          [(ngModel)]="qtyInvRequested"
          min="0"
          class="smallTextBox"
        />
      </div>
      <div class="col-sm-6 pr-0">
        <label>Qty : </label><br />
        <input
          name="txtQty"
          type="number"
          [(ngModel)]="qtySubletRequested"
          min="0"
          class="smallTextBox"
        />
      </div>
    </div>
    <div class="text-right" style="width:calc(100% - 250px);position:relative">
      <input
        type="button"
        (click)="AddProductToRental()"
        *ngIf="
          (qtyInvRequested > 0 || qtySubletRequested > 0) &&
          (qtyAvailableInv >= qtyInvRequested &&
            qtyAvailableSublet >= qtySubletRequested) &&
          selectedCategory != undefined &&
          selectedSubCategory != undefined &&
          selectedProduct != undefined
        "
        value="Add Product"
        class="normalButton"
        style="position: absolute;bottom: 0px;right: 0px;"
      />
    </div>
  </div>
  <div class="row m-0">
    <div class="col-sm-12">
      <app-grid
        [models]="LocationHeader.locationDetailDTOs"
        [lstGridColumn]="[
          {
            index: 1,
            name: 'productName',
            header: 'Product Name',
            readonly: true
          },
          {
            index: 2,
            name: 'qtyRequested',
            header: 'Qty',
            readonly: true,
            mask: '0999'
          },
          {
            index: 3,
            name: 'qtyFromInventory',
            header: 'Qty Inventory',
            readonly: false
          },
          {
            index: 4,
            name: 'qtyFromSublet',
            header: 'Qty Sublet',
            readonly: false
          }
        ]"
        entity="LocationDetail"
        (editFunction)="modifyProductRental($event)"
        (deleteFunction)="deleteProductRental($event)"
        (validationFunction)="validateProductRental($event)"
      ></app-grid>
    </div>
    <div class="col-sm-12" *ngIf="showInventoryItemGrid">
      <fieldset>
        <legend>Inventory Items</legend>
        <div *ngFor="let locationDetail of LocationHeader.locationDetailDTOs">
          <div
            *ngFor="
              let locationItem of locationDetail.locationItems;
              let i = index
            "
          >
            <div *ngIf="locationItem.isActive">
              <label class="col-sm-12 col-md-6 p-0">{{
                locationItem.productName
              }}</label>
              <select
                name="ddlInventoryItem"
                class="col-sm-12 col-md-6"
                [(ngModel)]="locationItem.inventoryItem_id"
                (ngModelChange)="validateSameItem($event, i)"
              >
                <option
                  *ngFor="let item of locationItem.lstInventoryItem"
                  [ngValue]="item.id"
                  >{{ item.description }}</option
                >
              </select>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
    <!--
      <div class="col-sm-12" *ngIf="LocationHeader.subletItems != null">
        <fieldset>
          <legend>Sublet Items</legend>
          <div
            *ngFor="let subletItem of LocationHeader.subletItems; let i = index"
          >
            <label class="col-sm-12 col-md-4 p-0">{{
              subletItem.productName
            }}</label>
            <select
              name="ddlSupplier"
              class="col-sm-12 col-md-6"
              [(ngModel)]="subletItem.supplier_id"
            >
              <option
                *ngFor="let supplier of subletItem.lstSuppliers"
                [ngValue]="supplier"
                >{{ supplier.name }}</option
              >
            </select>
            <input
              type="text"
              class="normalTextBox"
              [(ngModel)]="subletItem.inventoryTag"
            />
          </div>
        </fieldset>
      </div>
    -->
  </div>

  <div class="col-sm-4 offset-sm-8 text-right">
    <input
      type="button"
      (click)="Save()"
      value="Save"
      *ngIf="!requestSent"
      class="normalButton"
    />
    <mat-spinner
      class="col-sm-4 offset-sm-10 SmallMat-spinner"
      *ngIf="requestSent"
    ></mat-spinner>
  </div>
</fieldset>
