import { ClientArea } from './ClientArea';
import { ClientFoapal } from './ClientFoapal';

export class Client {
  id: number;
  isActive: boolean;
  name: string;
  createdBy_id: number;
  dateCreated: Date;
  lastUpdatedBy_id: number;
  lastUpdatedDate: Date;
  clientType_id: number;
  street: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  contactName: string;
  officePhone: string;
  cellPhone: string;
  email: string;
  notes: string;
  areas: ClientArea[];
  foapals: ClientFoapal[];

  constructor() {
    this.id = 0;
    this.isActive = true;
    this.name = null;
    this.createdBy_id = 0;
    this.dateCreated = new Date();
    this.lastUpdatedBy_id = null;
    this.lastUpdatedDate = null;
    this.clientType_id = 1;
    this.street = null;
    this.city = null;
    this.state = null;
    this.country = null;
    this.postalCode = null;
    this.contactName = null;
    this.officePhone = null;
    this.cellPhone = null;
    this.email = null;
    this.notes = null;
    this.areas = [];
  }
}
