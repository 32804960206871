export class PaymentMode {
  id: number;
  isActive: boolean;
  name: string;

  constructor() {
    this.id = 0;
    this.isActive = true;
    this.name = '';
  }
}
