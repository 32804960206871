import { HttpHeaders, HttpParams } from '@angular/common/http';

export const environment = {
  production: true,
  apiUrl: 'https://api.usids.ca/api/v1/',
  classicUrl: 'https://classic.usids.ca/',
  configNoToken: {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: '*/*'
    }
  }
};

export const configToken: IRequestOptions = {
  headers: {
    Authorization: 'Bearer ',
    Accept: '*/*'
  },
  observe: 'response'
};

export interface IRequestOptions {
  headers?: HttpHeaders | { [header: string]: string | string[] };
  observe?: any;
  params?: HttpParams | { [param: string]: string | string[] };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}
